export const toggleButton = {
  fill: "white",
  fontSize: 60,
  stroke: "#2c5a14",
  strokeThickness: 2
}

export const toggleButtonPicked = {
  ...toggleButton,
  stroke: "b24b23"
}

export const playButton = {
  ...toggleButtonPicked,
  fontSize: 120,
  align: "center",
}

export const popupHeadlineStyle = {
  align: "center",
  // fill: [
  //   "#c78fd0",
  //   "#fdeafe",
  //   "#c78fd0"
  // ],
  // fontFamily: "Ubuntu",
  // fontSize: 70,
  strokeThickness: 1,
  // textBaseline: "middle"
}

export const popupValueStyle = {
  align: "center",
  fill: [
    "#ffb209",
    "#9c4500",
    "#ffb200",
    "#ffffa3",
    "#ffff6e"
  ],
  // fontFamily: "ELANITCSTD-BLACK",
  // fontSize: 160,
  stroke: "#4e1600",
  strokeThickness: 5,
  // textBaseline: "middle"
}

export const chestValueStyle = {
  ...popupValueStyle,
  fontFamily: "Ubuntu Bold",
  fontSize: 70,
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 5,
  dropShadowColor: "#fffc13",
  dropShadowDistance: 0
}