import FlashLib from "flashlib";
import {popupHeadlineStyle, popupValueStyle} from "../ui/textStyles";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameModel from "../models/GameModel";

export default class BonusPopupStartController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.visible = false;
    this.interactive = true;

    this._stopButton = null;

    this.init();
  }

  init() {
    const attention = this.getChildByName('bonus_popup_start_0');
    const moreKeys = this.getChildByName('bonus_popup_start_2');
    moreKeys.fitSize(true, true);
    // attention.style = moreKeys.style = popupHeadlineStyle;

    this._stopButton = this.getChildByName('btn_stop_bonus');
    this._wantButton = this.getChildByName('btn_want_bonus');
    this._stopButton.children[1].correctPosition('center', 'center');
    this._wantButton.children[1].correctPosition('center', 'center');
    this._stopButton.enabled = true;
    this._wantButton.enabled = true;

    this._stopButton.onClick = () => {
      this.visible = false;
    }

    this._wantButton.onClick = () => {
      this.visible = false;
      GameModel.isBonusGame = true;
      GlobalDispatcher.dispatch('bonus:start');
    }

    GlobalDispatcher.add('bonus:show-rules', () => this.visible = true, this);
  }


}
