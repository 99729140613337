import GlobalDispatcher from './libs/game-engine/src/events/GlobalDispatcher';
import GameModel from './models/GameModel';
import { collect, requestCoinMinigame } from './api/rest';
import GameEvents from './data/events';
import ControllerSounds, { eSoundType } from './sounds/controllerSounds';

export default new class CoinMinigame {
  constructor() {
    GlobalDispatcher.add(GameEvents.COIN_MINIGAME.SHOW, this.showMinigame.bind(this))
  }

  showMinigame(data) {
    if (!data.params) data.params = { canTake: false };
    if (coinGameOP.ready()) {
      window.coinGameOP.show(
        GameModel.bets[0],
        GameModel.win_amount,
        GameModel.currency.toUpperCase(),
        this.onMinigameRequested.bind(this),
        this.onCollectClicked.bind(this),
        data.params.canTake
      );
    } else {
      setTimeout(this.showMinigame.bind(this), 300);
    }
  }

  async onMinigameRequested() {
    const response = await requestCoinMinigame();
    GlobalDispatcher.dispatch(GameEvents.UI.CLOSE_COIN_POPUP);
    GameModel.change({
      pickedBetIndex: response.bet_index || GameModel.pickedBetIndex,
      betAmount: (response.bet_index && GameModel.bets_available[response.bet_index]) || GameModel.betAmount,
    });
    GameModel.parseFreeBets(response);
    window.coinGameOP.showResult(response.is_win, response.win_amount);
    window.OPWrapperService.freeBetsController.show();
  }

  async onCollectClicked() {
    const response = await collect();

    GameModel.change({
      ...GameModel,
      ...response,
      pickedBetIndex: response.bet_index || GameModel.pickedBetIndex,
      betAmount: (response.bet_index && GameModel.bets_available[response.bet_index]) || GameModel.betAmount,
    });

    GameModel.parseFreeBets(response);

    ControllerSounds.playSound(eSoundType.COIN_COLLECT);
    GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, true);
    GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);
    GlobalDispatcher.dispatch(GameEvents.UI.CLOSE_COIN_POPUP);
    window.OPWrapperService.freeBetsController.updateTotalWin();
    window.OPWrapperService.freeBetsController.show();
  }
}
