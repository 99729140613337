import FlashLib from "flashlib";
import GameModel from "../models/GameModel";



export default class PersonIconGroupController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.init();
  }

  init() {
    GameModel.characters.forEach((char, index) => {
      const person = this.createPersonIcon(char, index);
      person.y -= 200;
      person.x -= 460
      person.x += index * 180;

      person.init();
    });
  }

  createPersonIcon(char, index) {
    const person = FlashLib.createItemFromLibrary(
        `movieClips/perses/${char}`,
        'FlashLib'
    );

    person.index = index;
    person.name = char;

    this.addChild(person);
    return person;
  }

}