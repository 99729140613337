export default class SoundSource {
  constructor(soundController, audioElement) {
    this.soundController = soundController;
    this.audioElement = audioElement;
  }

  play() {
    if(this.soundController.soundsMuted) return;
    this.audioElement.currentTime = 0;
    this.audioElement.play();
  }
}
