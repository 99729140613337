import FlashLib from 'flashlib';

export default class TopPanel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.labelTitle = this.getChildByName('submodule_coin_bet');
    this.labelDesc = this.getChildByName('desc');

    this.button = this.getChildByName('button');
    this.button.visible = false;
    const label = this.button.getChildByName('submodule_coin_take');
    label.style = {
      ...label.style,
      stroke: '#3711c5',
      strokeThickness: 5
    };
  }

  showStart(amount, currency) {
    this.button.visible = false;
    this.labelDesc.visible = true;

    this.labelTitle.text = window.OPWrapperService.localizations.getLocalizedText('submodule_coin_bet');
    this.labelDesc.text = `${amount} ${currency}`;
  }

  showCollect(amount, currency) {
    this.button.visible = true;
    this.labelDesc.visible = false;

    this.labelTitle.text = `${amount} ${currency}`;
  }

  addListenerButtonClick(callback) {
    this.button.on('pointertap', callback);
  }

  enable(value) {
    this.button.enabled = value;
  }
}
