import FlashLib from "flashlib";

export default class BonusTurnsController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this._valueChild = null;

    this.init();
  }

  init() {
    this._valueChild = this.getChildByName('value');
  }

  setValue(value) {
    this._valueChild.text = value.toString();
  }

}