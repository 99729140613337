import eAnimationType from './eAnimationType';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.THIMBLE, src: 'assets/animations/thimble/thimble.json' },
      { type: eAnimationType.CHEST, src: 'assets/animations/chest/chest_skeleton.json' },
      { type: eAnimationType.BONUS_BUTTON, src: 'assets/animations/bonus_game_icon/bonus_game_button.json' },
      { type: eAnimationType.PRISONER, src: 'assets/animations/prisoner/prisoner_fred.json' },
      { type: eAnimationType.PIRATE, src: 'assets/animations/pirate/pirate.json' },
      { type: eAnimationType.MEXICAN, src: 'assets/animations/mexican/mexican.json' },
      { type: eAnimationType.ASIAN, src: 'assets/animations/asian/anime.json' },
      { type: eAnimationType.JACKPOT_PANEL, src: 'assets/animations/jackpot_panel/big_win_pannel.json' },
      { type: eAnimationType.COIN_ICON, src: 'assets/animations/coin_icon/coin_mini_game.json' },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    this.app = app;
    for (let item of this.animationsData) {
      app.loader.add(item.type, item.src);
    }
    app.loader.load(this.onAnimationLoaded.bind(this));
  }

  onAnimationLoaded (_, resources) {
    this.resources = resources;
    this.onLoadingComplete();
  };

  createAnimation(type) {
    if (!window.OPWrapperService.UserAgentDetector.isOpera) {
      this.resources[type].spineAtlas.pages.forEach(page => {
        page.baseTexture.alphaMode = PIXI.ALPHA_MODES.PMA;
      });
    }

    return new PIXI.spine.Spine(this.resources[type].spineData);
  }
}

export default new AnimationsLoader();
