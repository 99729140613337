import FlashLib from 'flashlib';

import BaseButton from './base/Button';

import TopPanel from './ui/TopPanel';
import ButtonBet from './ui/ButtonBet';
import CoinView from './ui/CoinView';

FlashLib.registerClass('Button', BaseButton);

FlashLib.registerClass('TopPanel', TopPanel);
FlashLib.registerClass('ButtonBet', ButtonBet);
FlashLib.registerClass('CoinView', CoinView);
