export default {
  CANVAS_WIDTH_LANDSCAPE: 1917,
  CANVAS_HEIGHT_LANDSCAPE: 1080,

  CANVAS_WIDTH_PORTRAIT: 1080,
  CANVAS_HEIGHT_PORTRAIT: 1917,

  SAFE_WIDTH_LANDSCAPE: 1900,
  SAFE_HEIGHT_LANDSCAPE: 1070,

  SAFE_WIDTH_PORTRAIT: 1900,
  SAFE_HEIGHT_PORTRAIT: 1070
}
