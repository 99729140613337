import BaseButton from '../base/Button';

export default class ButtonBet extends BaseButton {
  constructor(data, displayData) {
    super(data, displayData);

    this.labelTitle = this.getChildByName('submodule_coin_start');
    this.labelDesc = this.getChildByName('desc');

    this.labelTitle.style = {
      ...this.labelTitle.style,
      stroke: "#AD3F1D",
      strokeThickness: 4,
      lineJoin: "round",
    };

    this.labelDesc.style = {
      ...this.labelDesc.style,
      stroke: "#AD3F1D",
      strokeThickness: 4,
      lineJoin: "round",
    };
  }

  show(amount, currency) {
    this.labelDesc.text = `${amount} ${currency}`;
  }
}
