import FlashLib from 'flashlib';

export default class LocalizeTextField extends FlashLib.TextField {
  constructor(data, displayData) {
    super(data, displayData);
    this.style.fontFamily = [ this.style.fontFamily, 'InterExtraBold' ];
    window.OPWrapperService.localizations.addTextField(this);
  }

  setTextParams(...params) {
    this.text = window.OPWrapperService.localizations.getLocalizedText(this.name, params);
  }

  destroy(options) {
    window.OPWrapperService.localizations.removeTextField(this);
    super.destroy(options);
  }
}
