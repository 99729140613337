import FlashLib from "flashlib";
import GameModel from "../models/GameModel";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";

export default class BonusGameController extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.visible = false;
    this.interactive = true;
    this._turnsPanel = null;

    this.init();
  }

  init() {
    GlobalDispatcher.add('bonus:start', this.onBonusStart, this);
    GlobalDispatcher.add('bonus:close', this.onBonusEnd, this);
    GameModel.subscribe(this.onGameModelChanged, this);

    this._turnsPanel = this.getChildByName('turns_panel');
    this._winAmount = this.getChildByName('amount_panel')
  }

  destroy() {
    super.destroy();
    GameModel.unsubscribe(this.onGameModelChanged, this);
    GlobalDispatcher.remove('bonus:start', this.onBonusStart, this);
    GlobalDispatcher.remove('bonus:close', this.onBonusEnd, this);
  }

  onGameModelChanged(gameModel) {
    this._turnsPanel.setValue(gameModel.bonus_game.keys);
    this._winAmount.setValue(gameModel.bonus_game.prize);
  }

  onBonusStart() {
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    this.visible = true;
  }

  onBonusEnd() {
    OPWrapperService.realityCheck.blockedRealityCheck = false;
    this.visible = false;
  }

}
