import FlashLib from "flashlib";
import {getBonusGame} from "../api/rest";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameModel from "../models/GameModel";
import {sleep} from "../utils/sleep";
import GameEvents from '../data/events';
import { TweenMax } from 'gsap/TweenMax';
import { TimelineMax } from 'gsap';

export default class ChestGroupController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);
    this.init();
    this._queue = [];
  }

  init() {
    this.children.forEach((chest, i) => {
      chest.index = i;
      chest.on('pointertap', () => { this.onChestClick(chest) });
    });

    if(GameModel.bonus_game.opened_chests) {
      this.openAllClosedChests(GameModel.bonus_game.opened_chests);
    }

    GlobalDispatcher.add('chest:cloaseAll', this.closeAllChest, this);
  }

  destroy() {
    super.destroy();
    this.children.forEach(chest => {
      chest.off('pointertap', () => { this.onChestClick(chest) });
    });
  }

  onChestClick(chest) {
    chest.disable();
    this.shakeChest(chest);
    this._queue.push(chest);
    if (this._queue.length >= GameModel.bonus_game.keys) {
      this.interactiveChildren = false;
    }
    this.tryOpenChests();
  }

  tryOpenChests() {
    if (this._opens) return;
    this._opens = true;
    this.openNextChest();
  }

  openNextChest() {
    if (this._queue.length === 0 || GameModel.bonus_game.keys === 0) {
      this._queue.forEach(this.stopShakingChest);
      this._queue = [];
      this._opens = false;
      return;
    }
    const chest = this._queue[0];
    this.onChestOpen(chest)
      .finally(() => {
        this._queue.shift()
        this.openNextChest()
      })
    ;
  }

  shakeChest(chest) {
    const timeline = new TimelineMax({paused: true, repeat: -1})
    // timeline.to(chest, 0.05, {x: '+=5', yoyo: true, repeat: 5}, 0)
    timeline.to(chest, 0.1, {y: '+=10', yoyo: true, repeat: 5 }, 0)
    // timeline.to(chest, 0.05, {x: '-=5', yoyo: true, repeat: 5 }, 0.05)
    timeline.to(chest, 0.1, {y: '-=10', yoyo: true, repeat: 5 }, 0.1)
    timeline.play();
  }

  stopShakingChest(chest) {
  TweenMax.killTweensOf(chest);
  TweenMax.to(chest, 0.05, { x: chest.displayData.x, y: chest.displayData.y })
  }


  async onChestOpen(chest) {
    const data = await getBonusGame(chest.index);

    GameModel.change({
      balance: data.balance,
      is_win: data.is_win,
      win_amount: data.win_amount,
      bonus_game: {
        ...GameModel.bonus_game,
        ...data.bonus_game
      },
      pickedBetIndex: data.bet_index || GameModel.pickedBetIndex,
      betAmount: (data.bet_index && GameModel.bets_available[data.bet_index]) || GameModel.betAmount,
      is_bonus_game_available: data.is_bonus_game_available
    });

    GameModel.parseFreeBets(data)

    this.stopShakingChest(chest);
    chest.open(data.bonus_game.win_amount);

    GameModel.bonus_game.progress_bars.forEach((progress, index) => {
      GlobalDispatcher.dispatch(GameEvents.CHARACTER.UPDATE_PROGRESS_BAR, {progress, index});
    })


    if(!data.bonus_game.chests) return;
    await sleep(2000);
    this.openAllClosedChests(data.bonus_game.chests);
    await sleep(3000);
    GlobalDispatcher.dispatch('bonus:collect', data);

    GameModel.change({
      balance: data.balance,
      bonus_game: {
        ...GameModel.bonus_game,
        progress: data.bonus_game.progress
      }
    });
    window.OPWrapperService.freeBetsController.show();
  }

  openAllClosedChests(chests) {
    if(!chests) return;

    for(let key in chests) {
      const value = chests[key];
      const index = parseInt(key.split('_')[1]);
      const botChest = this.children.filter(child => child.index == index)[0];
      //
      this.stopShakingChest(botChest);
      botChest.open(value, false);
    }
  }

  closeAllChest() {
    this.children.forEach((chest, i) => {
      chest.close();
    });
    this.interactiveChildren = true;
  }

}
