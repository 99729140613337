import BaseButton from "../libs/game-engine/src/base/ui/BaseButton";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";

export default class BonusPopupButtonController extends BaseButton {

  constructor(data, displayData) {
    super(data, displayData);

    this.enabled = true;
  }

  onClick() {
    super.onClick();
    GlobalDispatcher.dispatch('bonus:close');
  }

}