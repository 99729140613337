import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib';

export default class CoinView extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    const spineData = PIXI.Loader.shared.resources['coin_animation'].spineData;
    this.animation = new PIXI.spine.Spine(spineData);
    this.animation.x = this.animation.width / 2 - 27;
    this.animation.y = this.animation.height / 2 - 20;
    this.animation.scale.set(0.9);
    this.addChild(this.animation);

    this.animation.state.addListener({
      complete: this._onAnimationComplete.bind(this)
    });
    this.getChildAt(0).visible = false;
    this.onStopCompleteOnce = () => {
    };
  }

  init() {
    const entry = this.animation.state.setAnimation(0, 'x2', false);
    entry.trackTime = entry.trackEnd;
    // this.animation.state.clearTracks();
  }

  startAnimation() {
    this.needStop = false;
    this.animation.state.setAnimation(0, 'start', false);
    this.animation.state.addAnimation(0, 'spin', true, 0);
  }

  stopAnimation(isWin) {
    this.needStop = true;
    this.stopAnimationName = isWin ? 'x2' : 'x0.5';
  }

  _onAnimationComplete(data) {
    if (data.animation.name === 'spin' && this.needStop) {
      this.animation.state.clearTracks();
      this.animation.state.addAnimation(0, this.stopAnimationName, false, 0);
    } else if (data.animation.name === 'x2' || data.animation.name === 'x0.5') {
      this.onStopCompleteOnce();
      this.onStopCompleteOnce = () => {
      };
    }
  }
}
