import BaseButton from '../libs/game-engine/src/base/ui/BaseButton';
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";

export default class ToggleButton extends BaseButton {

  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.enabled = false;

    this._thimbleValue = 0;
    this._thimbleText = null;
    this._frameState = 1;
  }

  // Override parent method
  setSate(frameIndex) {
    let state = ''
    switch (frameIndex) {
      case 1:
        state = 'out';
        break;
      case 2:
        state = 'over';
        break;
      case 3:
        state = 'down';
        break;
      case 4:
        state = 'disabled';
        break;
    }
    if(state !== '') {
      this.previousState = this.state;
      this.state = state;
    }

    this.getChildByName('button').goToFrame(frameIndex);
  }

  onClick() {
    super.onClick();
    GlobalDispatcher.dispatch('toggleButton:clicked', this);
  }

  setThimbleValue(value) {
    this._thimbleValue = value;

    this._thimbleText = this.getChildByName('value');
    this._thimbleText.x += 20;
    this._thimbleText.y += 20;
    this._thimbleText.text = `x${value}`;
  }

  setThimbleValueStyle(style) {
    this._thimbleText.style = style;
  }

  setFrameState(frame) {
    this._frameState = frame;
    this.goToFrame(frame);
  }

  get thimbleValue() { return this._thimbleValue; }
  get frameState() { return this._frameState; }

}
