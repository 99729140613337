import FlashLib from 'flashlib';

import ToggleButton from "./ui/ToggleButton";
import ToggleController from "./ToggleController/ToggleController";
import ThimbleGroupController from "./ThimbleGroupController/ThimbleGroupController";
import ThimbleController from "./ThimbleController/ThimbleController";
import BetSelectorController from "./BetSelectorController/BetSelectorController";
import BackgroundController from "./BackgroundController/BackgroundController";
import WinAreaController from "./WinAreaController/WinAreaController";
import BetButtonController from "./ui/BetButtonController";
import BalanceController from "./BalanceController/BalanceController";

import BonusTurnsController from "./BonusGameControllers/BonusTurnsController";
import BonusWinAmountController from "./BonusGameControllers/BonusWinAmountController";
import ChestGroupController from "./BonusGameControllers/ChestGroupController";
import ChestController from "./BonusGameControllers/ChestController";
import ArrowController from "./ArrowController/ArrowController";
import BonusGameController from "./BonusGameControllers/BonusGameController";
import IconBonusGame from "./BonusGameControllers/IconBonusGame";
import PersonIconGroupController from "./Person/PersonIconGroupController";
import PersonIconController from "./Person/PersonIconController";
import ProgressBarController from "./Person/ProgressBarController";
import BonusPopupController from "./BonusGameControllers/BonusPopupController";
import BonusPopupButtonController from "./BonusGameControllers/BonusPopupButtonController";
import BaseButton from "./libs/game-engine/src/base/ui/BaseButton";
import AutoplayButtonController from "./ui/AutoplayButtonController";
import MaxBetButtonController from "./ui/MaxBetButtonController";
import BonusPopupStartController from "./BonusGameControllers/BonusPopupStartController";
import StartScreenController from "./StartScreenController";
import MenuButtonController from "./ui/MenuButtonController";
import ModalPaytable from "./ui/modalPaytable";
import TurnsController from "./TurnsController/TurnsController";
import TurnPanelController from "./TurnsController/TurnPanelController";
import CoinPopupController from './CoinPopupController/CoinPopupController';
import OpenCoinButton from './CoinPopupController/OpenCoinButton';
import GetKeyPopupController from './BonusGameControllers/GetKeyPopupController';

FlashLib.registerClass('ToggleButton', ToggleButton);
FlashLib.registerClass('ToggleController', ToggleController);

FlashLib.registerClass('ThimbleGroupController', ThimbleGroupController);
FlashLib.registerClass('ThimbleController', ThimbleController);
FlashLib.registerClass('ArrowController', ArrowController);

FlashLib.registerClass('BetSelectorController', BetSelectorController);

FlashLib.registerClass('BackgroundController', BackgroundController);
FlashLib.registerClass('WinAreaController', WinAreaController);
FlashLib.registerClass('BetButtonController', BetButtonController);
FlashLib.registerClass('BalanceController', BalanceController);

FlashLib.registerClass('BonusTurnsController', BonusTurnsController);
FlashLib.registerClass('BonusWinAmountController', BonusWinAmountController);
FlashLib.registerClass('ChestGroupController', ChestGroupController);
FlashLib.registerClass('ChestController', ChestController);
FlashLib.registerClass('BonusGameController', BonusGameController);
FlashLib.registerClass('IconBonusGame', IconBonusGame);
FlashLib.registerClass('BonusPopupController', BonusPopupController);
FlashLib.registerClass('BonusPopupStartController', BonusPopupStartController);
FlashLib.registerClass('BonusPopupButtonController', BonusPopupButtonController);

FlashLib.registerClass('PersonIconGroupController', PersonIconGroupController);
FlashLib.registerClass('PersonIconController', PersonIconController);
FlashLib.registerClass('ProgressBarController', ProgressBarController);

FlashLib.registerClass('AutoplayButtonController', AutoplayButtonController);
FlashLib.registerClass('MaxBetButtonController', MaxBetButtonController);
FlashLib.registerClass('StartScreenController', StartScreenController);
FlashLib.registerClass('MenuButtonController', MenuButtonController);
FlashLib.registerClass('ModalPaytable', ModalPaytable);


FlashLib.registerClass('TurnsController', TurnsController);
FlashLib.registerClass('TurnPanelController', TurnPanelController);
FlashLib.registerClass('CoinPopupController', CoinPopupController);
FlashLib.registerClass('OpenCoinButton', OpenCoinButton);
FlashLib.registerClass('GetKeyPopupController', GetKeyPopupController);

FlashLib.registerClass('BaseButton', BaseButton);
