import FlashLib from "flashlib";
import GameModel from "../models/GameModel";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import animationsLoader from "../animations/animationsLoader";
import eAnimationType from "../animations/eAnimationType";
import GameEvents from "../data/events";

const eAnimationName = {
  IDLE: 'idle'
};

export default class IconBonusGame extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.animation = animationsLoader.createAnimation(eAnimationType.BONUS_BUTTON);
    this._canBeActive = false;
    this.enabled = false;

    this.init();
  }

  init() {
    this.on('pointertap', this.onClick);
    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiDisabled, this);
    GlobalDispatcher.add('bonus:close', this.onUiDisabled, this);
  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  onClick() {
    GlobalDispatcher.dispatch('bonus:show-rules');
  }

  onUiDisabled({params}) {
    if(params && GameModel.is_bonus_game_available) {
      this.setActiveBtn();
    }
    else if(params && GameModel.is_bonus_game_available) {
      this.disableBtn();
    }
    else if(!params) {
      this.disableBtn();
    }
  }

  makeActive(data) {
    const keys = data.bonus_game.keys;

    if(keys > 0) {
      this.setActiveBtn();
    }
    else {
      this.disableBtn();
    }
  }

  setActiveBtn() {
    this._canBeActive = true;
    this.goToFrame(2);
    this.interactive = true;
    this.cursor = 'pointer';

    this.animation.x = this.getChildAt(0).x + this.getChildAt(0).width / 2;
    this.animation.y = this.getChildAt(0).y + (this.getChildAt(0).height / 2) - 10;
    this.animation.visible = true;

    this.getChildAt(0).visible = false;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);

    if(!this.children[1]) {
      this.addChild(this.animation);
    }
  }

  disableBtn() {
    this._canBeActive = false;
    this.goToFrame(1);
    this.interactive = false;
    this.cursor = null;
    this.children[0].alpha = 0.6;
    this.animation.visible = false;
  }

}
