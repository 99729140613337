import FlashLib from "flashlib";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameEvents from "../data/events";
import {sleep} from "../utils/sleep";
import GameModel from "../models/GameModel";



export default class PersonIconController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.index = 0;
    this.name = '';
    this.interactive = true;
    this.cursor = 'pointer';

    this._progressBar = null;
    this._progressBarHighlighter = null;
  }

  init() {
    this._progressBar = this.getChildByName('progress_bar');
    this._progressBarHighlighter = this.getChildByName('progressbar_highlighter');
    this._progressBarHighlighter.visible = false;

    const progress = GameModel.bonus_game.progress_bars[this.index];
    const index = this.index;

    this.setMask();
    this.updateProgressBar({params: {progress, index}});

    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiDisabled, this);
    GlobalDispatcher.add(GameEvents.CHARACTER.UPDATE_PROGRESS_BAR, this.updateProgressBar, this);
    this.on('pointertap', this.onClick, this);
  }

  destroy() {
    super.destroy();

    GlobalDispatcher.remove(GameEvents.UI.ENABLED, this.onUiDisabled, this);
    GlobalDispatcher.remove(GameEvents.CHARACTER.UPDATE_PROGRESS_BAR, this.updateProgressBar, this);
    this.off('pointertap', this.onClick, this);
  }

  setMask() {
    const graphics = new PIXI.Graphics();
    graphics.lineStyle(1, 0xa59562, 1);
    graphics.beginFill(0xa59562, 1);
    graphics.drawRoundedRect(
        this._progressBar.x,
        this._progressBar.y - this._progressBar.height,
        this._progressBar.width,
        this._progressBar.height,
        16
    );
    graphics.endFill();

    this._progressBar.mask = graphics;

    this.addChild(graphics);
  }

  onClick() {
    GlobalDispatcher.dispatch(GameEvents.CHARACTER.CHANGE_HERO, {name: this.name, index: this.index});
  }

  onUiDisabled({params}) {
    this.interactive = params;
    this.cursor = params ? 'pointer' : null;
  }

  async updateProgressBar({params}) {
    if(params.index != this.index) return;
    if(params.progress >= 1) {
      this.goToFrame(3);
    }

    if(params.progress < 1) {
      this.goToFrame(1);
      this._progressBar = this.getChildByName('progress_bar');
      this._progressBar.updateBar(params.progress);
      this._progressBarHighlighter = this.getChildByName('progressbar_highlighter');
      if(params.progress == 0) {
        this._progressBarHighlighter.visible = false;
      }
      else {
        this._progressBarHighlighter.visible = true;
        await sleep(1000);
        this._progressBarHighlighter.visible = false;
      }
    }

  }

}