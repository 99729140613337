import SoundSource from './soundSource';
import { getFromStorage, setToStorage } from '../utils/localStorage';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import GameEvents from '../data/events';

export const eSoundType = {
  THIMBLE_CLICK: 'thimble_click',
  THIMBLE_SHUFFLE: 'thimble_shuffle',
  THIMBLE_OPEN: 'thimble_open',
  WIN: 'win',
  LOOSE: 'loose',
  COIN_COLLECT: 'coin_collect',
  BG_JAIL: 'prisoner',
  BG_PIRATE: 'pirate',
  BG_DIABLO: 'diablo',
  BG_ASIA: 'asia',

  ASIA_IDLE: 'asia_idle',
  ASIA_INTERNOISE: 'asia_internoise',
  ASIA_WIN: 'asia_win',
  ASIA_LOOSE: 'asia_loose',

  MEXICAN_IDLE: 'diablo_idle',
  MEXICAN_INTERNOISE: 'diablo_internoise',
  MEXICAN_WIN: 'diablo_win',
  MEXICAN_LOOSE: 'diablo_loose',

  PIRATE_IDLE: 'pirate_idle',
  PIRATE_INTERNOISE: 'pirate_internoise',
  PIRATE_WIN: 'pirate_win',
  PIRATE_LOOSE: 'pirate_loose',

  PRISONER_IDLE: 'prisoner_idle',
  PRISONER_INTERNOISE: 'prisoner_internoise',
  PRISONER_WIN: 'prisoner_win',
  PRISONER_LOOSE: 'prisoner_loose'
};

class ControllerSounds {

  constructor() {
    this.assetsList = [
      { id: eSoundType.THIMBLE_CLICK, src: 'assets/sounds/thimble_click.mp3' },
      { id: eSoundType.THIMBLE_SHUFFLE, src: 'assets/sounds/thimble_shuffle.mp3' },
      { id: eSoundType.THIMBLE_OPEN, src: 'assets/sounds/thimble_open.mp3' },
      { id: eSoundType.WIN, src: 'assets/sounds/win.mp3' },
      { id: eSoundType.LOOSE, src: 'assets/sounds/loose.mp3' },
      { id: eSoundType.COIN_COLLECT, src: 'assets/sounds/coin_collect.mp3' },
      { id: eSoundType.BG_JAIL, src: 'assets/sounds/bg_jail.mp3', loop: true },
      { id: eSoundType.BG_PIRATE, src: 'assets/sounds/bg_pirates.mp3', loop: true },
      { id: eSoundType.BG_DIABLO, src: 'assets/sounds/bg_diablo.mp3', loop: true },
      { id: eSoundType.BG_ASIA, src: 'assets/sounds/bg_asia.mp3', loop: true },

      { id: eSoundType.ASIA_IDLE, src: 'assets/sounds/character/asia_idle.mp3', loop: true },
      { id: eSoundType.ASIA_INTERNOISE, src: 'assets/sounds/character/asia_internoise.mp3', loop: true },
      { id: eSoundType.ASIA_WIN, src: 'assets/sounds/character/anime_win_1.mp3', loop: false },
      { id: eSoundType.ASIA_LOOSE, src: 'assets/sounds/character/anime_win_1.mp3', loop: false },

      { id: eSoundType.MEXICAN_IDLE, src: 'assets/sounds/character/diablo_idle.mp3', loop: true },
      { id: eSoundType.MEXICAN_INTERNOISE, src: 'assets/sounds/character/diablo_internoise.mp3', loop: true },
      { id: eSoundType.MEXICAN_WIN, src: 'assets/sounds/character/mexican_win_1.mp3', loop: false },
      { id: eSoundType.MEXICAN_LOOSE, src: 'assets/sounds/character/mexican_lost_1.mp3', loop: false },

      { id: eSoundType.PIRATE_IDLE, src: 'assets/sounds/character/pirate_idle.mp3', loop: true },
      { id: eSoundType.PIRATE_INTERNOISE, src: 'assets/sounds/character/pirate_internoise.mp3', loop: true },
      { id: eSoundType.PIRATE_WIN, src: 'assets/sounds/character/pirate_win_1.mp3', loop: false },
      { id: eSoundType.PIRATE_LOOSE, src: 'assets/sounds/character/pirate_lost_1.mp3', loop: false },

      { id: eSoundType.PRISONER_IDLE, src: 'assets/sounds/character/prisoner_idle.mp3', loop: true },
      { id: eSoundType.PRISONER_INTERNOISE, src: 'assets/sounds/character/prisoner_internoise.mp3', loop: true },
      { id: eSoundType.PRISONER_WIN, src: 'assets/sounds/character/prisoner_win_1.mp3', loop: false },
      { id: eSoundType.PRISONER_LOOSE, src: 'assets/sounds/character/prisoner_lost_1.mp3', loop: false },
    ];

    this.sounds = {};
    this.volume = 0.1;
    this.SFXPlaying = false;

    this.startScreenHide = false;

    this.musicMuted = getFromStorage('musicMuted');
    if (this.musicMuted === null) {
      setToStorage('musicMuted', 'false');
      this.musicMuted = false;
    }

    this.soundsMuted = getFromStorage('soundsMuted');
    if (this.soundsMuted === null) {
      setToStorage('soundsMuted', 'false');
      this.soundsMuted = false;
    }

    document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
  }

  startLoading(loadingComplete) {
    this.loadingComplete = loadingComplete;
    for (const item of this.assetsList) {
      const audioElement = new Audio(item.src);
      audioElement.loop = item.loop;
      audioElement.volume = this.volume;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent))
        audioElement.autoplay = true;//this is hack for iOS shit
      audioElement.addEventListener('loadeddata', (event) => {
        this.onSoundLoaded(item);
      });
      this.sounds[item.id] = audioElement;
    }
  }

  onSoundLoaded(data) {
    this.assetsList.map((item) => {
      if (item.id === data.id) {
        item.loaded = true;
      }
    });

    this.checkLoadingComplete();
  };

  onVisibilityChange() {
    if (this.musicMuted) return;

    if (document.hidden) {
      // this.pauseSFX();
      for(let key in this.sounds) {
        this.sounds[key].muted = true;
      };
    } else {
      // this.playSFX();
      for(let key in this.sounds) {
        this.sounds[key].muted = getFromStorage('soundsMuted');
      }
    }
  };

  checkLoadingComplete() {
    let loadingComplete = false;
    for (let item of this.assetsList) {
      if (item.loaded) {
        loadingComplete = true;
      } else {
        loadingComplete = false;
        break;
      }
    }

    if (loadingComplete) {
      this.loadingComplete();
    }
  };

  createSoundSource(type) {
    const item = this.assetsList.find((el) => el.id === type);
    const audioElement = new Audio(item.src);
    audioElement.volume = this.volume;

    return new SoundSource(this, audioElement);
  }

  playSound(type) {
    if (this.soundsMuted) return;
    this.sounds[type].currentTime = 0;
    return this.sounds[type].play();
  }

  stopSound(type) {
    this.sounds[type].pause();
    this.sounds[type].currentTime = 0;
  }

  muteSFX() {
    this.musicMuted = true;
    setToStorage('musicMuted', 'true');
    this.pauseSFX();
  }

  unmuteSFX() {
    this.musicMuted = false;
    setToStorage('musicMuted', 'false');
    this.playSFX();
  }

  setSoundsMuted(value) {
    this.soundsMuted = value;
    setToStorage('soundsMuted', value);
    for(let key in this.sounds) {
      this.sounds[key].muted = this.soundsMuted;
    }
    GlobalDispatcher.dispatch(GameEvents.SOUNDS_TOGGLE);
  }

  playSFX() {
    if (this.SFXPlaying || this.musicMuted) return;

    this.musicMuted = false;
    setToStorage('musicMuted', 'false');

    document.removeEventListener('mousedown', this.playSFX);
    document.removeEventListener('touchstart', this.playSFX);
    this.sounds[eSoundType.EST_SFX].play()
      .then(() => this.SFXPlaying = true)
      .catch(() => {
        document.addEventListener('mousedown', this.playSFX);
        document.addEventListener('touchstart', this.playSFX);
      });
  };

  pauseSFX() {
    this.SFXPlaying = false;
    this.sounds[eSoundType.EST_SFX].pause();
    document.removeEventListener('mousedown', this.playSFX);
    document.removeEventListener('touchstart', this.playSFX);
  }
}

export default new ControllerSounds();
