const GameEvents = {
  UI: {
    ENABLED: 'GameEvents:UI:Enabled',
    MUTE_SOUND: 'GameEvents:UI:MUTE_SOUND',
    MUTE_BG_SOUND: 'GameEvents:UI:MUTE_BG_SOUND',
    COLLECT_NOW_BTN_ENABLED: 'GameEvents:UI:COLLECT_NOW_BTN_ENABLED',
    SET_BET: 'GameEvents:UI:SET_BET',
    SET_MAX_BET: 'GameEvents:UI:SET_MAX_BET',
    OPEN_COIN_POPUP: 'GameEvents:UI:OPEN_COIN_POPUP',
    CLOSE_COIN_POPUP: 'GameEvents:UI:CLOSE_COIN_POPUP',
    OPEN_KEY_POPUP: 'GameEvents:UI:OPEN_KEY_POPUP'
  },
  CHARACTER: {
    UPDATE_PROGRESS_BAR: 'GameEvents:CHARACTER:UPDATE_PROGRESS_BAR',
    CHANGE_HERO: 'GameEvents:CHARACTER:CHANGE_HERO'
  },
  GAME: {
    START: 'GameEvents:GAME:START',
    END: 'GameEvents:GAME:END',
    WIN: 'GameEvents:Game:WIN',
  },
  THIMBLE: {
    PICKED: 'GameEvents:THIMBLE:PICKED',
    COUNT_CHOOSED: 'GameEvents:THIMBLE:COUNT_CHOOSED',
  },
  COIN_MINIGAME: {
    SHOW: 'GameEvents:MINIGAME:SHOW'
  },
  BET: {
    DECREASE: 'GameEvents:BET:DECREASE',
    INCREASE: 'GameEvents:BET:INCREASE',
    MAX_BET: 'GameEvents:BET:MAX_BET',
    CHANGED: 'GameEvents:BET:CHANGED'
  },
  SOUNDS_TOGGLE: 'sounds_toggle',
}

export default GameEvents;
