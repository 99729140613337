import Game from './Game';

export default class GameInterface {
  constructor() {
    const game = new Game();

    this.show = (minBetAmount, amount, currency, onPlayClicked, onCollectClicked, canTake) => {
      game.show(minBetAmount, amount, currency, onPlayClicked, onCollectClicked, canTake);
    };

    this.showResult = (isWin, betAmount) => {
      game.showResult(isWin, betAmount);
    };

    this.ready = () => {
      return game.ready;
    }
  }
}
