export const epicWinValue = {
  anchor: {
    x: 0.5,
    y: 0.5
  },
  font: {
    name: 'epicFont',
    size: 140
  },
  align: 'center'
}

export const yellowLighterYellowOrage = {
  fontFamily: 'Ubuntu bold',
  fill: [
    "#fefd77",
    "#ffffe2",
    "#ffdd23",
    "#cf6900"
  ],
  fillGradientStops: [
    0.2,
    0.5,
    0.6,
    0.8
  ],
  stroke: "#ffb143",
  strokeThickness: 4,
  lineJoin: "round",
  letterSpacing: 2,

}
