import FlashLib from "flashlib";
import GameModel from "../models/GameModel";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameEvents from "../data/events";
import { formatCurrency } from '../utils/currency';

export default class TurnsController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this._thimblesCount = 0;

    this.init();
  }

  init() {
    GameModel.subscribe(this.onGameModelChanged, this);
    GlobalDispatcher.add(GameEvents.THIMBLE.COUNT_CHOOSED, this.onThimbleCountChosed, this);
    GlobalDispatcher.add(GameEvents.BET.CHANGED, this.setupLastPanel, this);
  }

  onThimbleCountChosed({params}) {
    this._thimblesCount = params;
    const limits = GameModel.bonus_game.limits[this._thimblesCount];

    if(this.children.length) {
      this.children.forEach(child => child.destroy());
      this.children.length = 0;
    }

    for(let i = 0; i < limits; i++) {
      this.createTurnPanel();
    }

    this.setupTurnPanelPositions();
    this.setupPanelView(GameModel.round - 1);
    this.setupLastPanel();
  }

  createTurnPanel() {
    const panel = FlashLib.createItemFromLibrary(
        'movieClips/turn_panel',
        'FlashLib'
    );

    this.addChild(panel);
    return panel;
  }

  setupTurnPanelPositions() {
    if(!this.children.length) return;

    const childCount = this.children.length;
    const childWidth = this.children[0].width;
    const offset = 10;
    const allChildsWidth = childCount * (childWidth + offset);
    const startPositionX = (this.width - allChildsWidth / 2) - this.width / 2;

    for(let i = 0; i < childCount; i++) {
      const x = startPositionX + (childWidth + offset) * i;
      this.children[i].x = x;
    }
  }

  setupPanelView(round) {
    const activePanel = round;

    GameModel.turns = [];

    for(let i = 0; i < this.children.length; i++) {

      const value = Math.pow(this._thimblesCount, i+1);
      //TODO: move to better place
      GameModel.turns.push(value);

      if(i === this.children.length - 1) return;

      this.children[i].setDisabled();

      if(i < activePanel) this.children[i].setPast();

      if(i === activePanel) this.children[i].setActive();

      if(i === this.children.length - 1) return;

      this.children[i].setValue(value);
    }
  }

  setupLastPanel() {
    const index = this.children.length - 1;
    const value = GameModel.bonus_game.limits_payout[GameModel.thimbles];
    const cash = formatCurrency(value * GameModel.betAmount, GameModel.decimal);

    this.children[index].setLastPanelFrame();
    this.children[index].setCashValue(cash);
    this.children[index].setValue(value);
  }

  onGameModelChanged(gameModel) {
    this.setupPanelView(gameModel.round - 1 || 0);

    if(this.children.length && gameModel.round - this.children.length === 1 && gameModel.is_win) {
      GlobalDispatcher.dispatch(GameEvents.UI.OPEN_COIN_POPUP);
    }
  }

}
