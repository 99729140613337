import ControllerSounds from "../sounds/controllerSounds";
import animationsLoader from "../animations/animationsLoader";
import GameModel from "../models/GameModel";



const eAnimationName = {
  IDLE: 'idle',
  WIN: 'win',
  LOOSE: 'lost',
};

export default class Character {

  constructor(name) {
    this.name = name;
    this.animation = this.createAnimation();
  }

  createAnimation() {

    const animation = animationsLoader.createAnimation(this.name);
    animation.stateData.defaultMix = 0.2;
    animation.state.clearTracks();
    animation.skeleton.setToSetupPose();
    animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);

    animation.state.addListener({
      complete: entry => {
        if(entry.animation.name === eAnimationName.WIN) {
          animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);
        }
        if(entry.animation.name === eAnimationName.LOOSE) {
          animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);
        }
      }
    });

    return animation;
  }

  show() {
    this.animation.visible = true;
  }

  hide() {
    this.animation.visible = false;
  }

  win() {
    this.animation.state.setAnimation(0, eAnimationName.WIN, false);
    ControllerSounds.playSound(`${this.name}_win`);
  }

  loose() {
    this.animation.state.setAnimation(0, eAnimationName.LOOSE, false);
    ControllerSounds.playSound(`${this.name}_loose`);
  }

  playSound() {
    if(!GameModel.gameStarted) return;
    ControllerSounds.playSound(this.name);
    ControllerSounds.playSound(`${this.name}_idle`);
    ControllerSounds.playSound(`${this.name}_internoise`);
  }

  stopSound() {
    ControllerSounds.stopSound(this.name);
    ControllerSounds.stopSound(`${this.name}_idle`);
    ControllerSounds.stopSound(`${this.name}_internoise`);
    ControllerSounds.stopSound(`${this.name}_win`);
    ControllerSounds.stopSound(`${this.name}_loose`);
  }

}
