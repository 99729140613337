import FlashLib from "flashlib";



export default class ProgressBarController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);
  }

  updateBar(value) {
    this.scale.set(value, 1);
  }

}