import FlashLib from 'flashlib';
import GameModel from './models/GameModel';
import GlobalDispatcher from './libs/game-engine/src/events/GlobalDispatcher';
import GameEvents from './data/events';
import { yellowLighterYellowOrage } from './fontStyles';

export default class StartScreenController extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.interactive = true;

    this._startButton = null;
    for (let i = 0; i < 4; i++) {
      const text = this.getChildByName(`start_screen_${i}`);
      text.style = {...text.style, ...yellowLighterYellowOrage};
    }

    this.init();
  }

  init() {
    this._startButton = this.getChildByName('startBtn');
    this._startButton.enabled = true;
    const startButtonLabel = this._startButton.getChildByName('start_screen_btn_start');
    startButtonLabel.correctPosition('center', 'center');

    this._startButton.onClick = () => {
      this.visible = false;
      GameModel.gameStarted = true;
      GlobalDispatcher.dispatch('game:StartScreenOff');
      GlobalDispatcher.dispatch(GameEvents.UI.MUTE_BG_SOUND, !localStorage.getItem('soundsMuted'));

      if (GameModel.game_type === 'bonus') {
        GlobalDispatcher.dispatch('bonus:start');
      }

      if(GameModel.isRestoredCoin) {
        if(GameModel.round > GameModel.bonus_game.limits[GameModel.thimbles]) {
          GlobalDispatcher.dispatch(GameEvents.UI.OPEN_COIN_POPUP);
        }

        GlobalDispatcher.dispatch(GameEvents.COIN_MINIGAME.SHOW, { canTake: true });
      }
      window.OPWrapperService.Fullscreen.showButtonIfAndroid();
      window.OPWrapperService.freeBetsController.show();
    }


  }

}
