import FlashLib from 'flashlib';
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import {toggleButton, toggleButtonPicked} from "../ui/textStyles";
import GameModel from "../models/GameModel";
import GameEvents from "../data/events";



export default class ToggleController extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._buttons = [];

    this.init();
  }

  init() {
    this._buttons = GameModel.toggleButtons.map(data => this.createButton(data));
    this._buttons.forEach((button, i) => this.positioningButtons(button, i));

    GlobalDispatcher.add('toggleButton:clicked', this.onButtonClick, this);
    GlobalDispatcher.add('game:Started', this.onGameStarted, this);
    GlobalDispatcher.add('game:Ended', this.onGameEnded, this);
    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiClickable, this);
    GlobalDispatcher.add('game:StartScreenOff', this.onStartScreenOff, this);
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove('toggleButton:clicked', this.onButtonClick, this);
    GlobalDispatcher.remove('game:Started', this.onGameStarted, this);
    GlobalDispatcher.remove(GameEvents.UI.ENABLED, this.onUiClickable, this);
    GlobalDispatcher.remove('game:StartScreenOff', this.onStartScreenOff, this);
  }

  onStartScreenOff() {
    const button = GameModel.isRestoredBaseGame || GameModel.isRestoredCoin
      ? this._buttons.find(button => button.thimbleValue === GameModel.thimbles)
      : this._buttons.find(button => button.isActive);

    this._buttons.forEach(btn => btn.isActive = false);
    button.isActive = true;

    this.setActiveButton(button, button.isActive);
  }

  createButton(data) {
    const button = FlashLib.createItemFromLibrary(
        'movieClips/buttons/button_toggle',
        'FlashLib'
    );

    button.setThimbleValue(data.count);
    button.isActive = data.active;

    this.addChild(button);

    button.enabled = false;

    return button;
  }

  // TODO: Do something with that, awful
  positioningButtons(button, index) {
    const offset = {x: -25, y: -25};
    const distance = 140;

    button.x = offset.x;
    button.y = offset.y;
    button.y += distance * index;
  }

  onButtonClick(event) {
    const button = event.params;

    this._buttons.forEach(btn => {
      this.setActiveButton(btn, btn == button);
    });
  }

  onUiClickable({params}) {
    this._buttons.forEach(button => button.enabled = params);
  }

  onGameStarted() {
    this._buttons.forEach(button => button.enabled = false);
  }

  onGameEnded() {
    this._buttons.forEach(button => button.enabled = true);
  }

  setActiveButton(button, condition) {
    const frameState = condition ? 2 : 1;

    button.setFrameState(frameState);

    if(condition) GameModel.thimbles = button.thimbleValue;
    if(condition) GlobalDispatcher.dispatch(GameEvents.THIMBLE.COUNT_CHOOSED, button.thimbleValue, this);
  }

}