import FlashLib from "flashlib";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameModel from "../models/GameModel";
import GameEvents from "../data/events";
import { formatCurrency } from '../utils/currency';

export default class WinAreaController extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.init();
  }

  init() {
    GlobalDispatcher.add(GameEvents.GAME.START, this.onGameStarted, this);
    GlobalDispatcher.add(GameEvents.GAME.END, this.onGameEnded, this);
    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiClickable, this);
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.GAME.START, this.onGameStarted, this);
    GlobalDispatcher.remove(GameEvents.GAME.END, this.onGameEnded, this);
    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiClickable, this);
  }

  onUiClickable({params}) {
    if(params) this.goToFrame(1);
  }

  onGameStarted() {
    // this.goToFrame(1);
  }

  onGameEnded(event) {
    const gameInfo = event.params;

    if(gameInfo.is_win) {
      this.goToFrame(2);
      const field = this.getChildByName('win_value');
      const nextWinValueField = this.getChildByName('center_panel_next_win_value');
      const modifier = GameModel.turns[GameModel.round - 1];
      let nextWinValue = GameModel.betAmount * modifier;

      field.text = `${formatCurrency(gameInfo.win_amount, GameModel.decimal)} ${GameModel.currency.toUpperCase()}`;
      field.fitSize(true, true);
      if(GameModel.round === GameModel.turns.length) {
        nextWinValue = GameModel.bonus_game.limits_payout[GameModel.thimbles] * GameModel.betAmount;
      }

      nextWinValueField.visible = GameModel.round <= GameModel.turns.length;
      nextWinValueField.text = `${window.OPWrapperService.localizations.getLocalizedText('center_panel_next_win_value')} ${formatCurrency(nextWinValue, GameModel.decimal)} ${GameModel.currency.toUpperCase()}`
      nextWinValueField.fitSize(true, true);
    }
    else {
      this.goToFrame(3)
      const loseText = this.getChildByName('center_panel_try_again');
      loseText.correctPosition('center', 'center');
    }
  }
}
