import FlashLib from "flashlib";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import { yellowLighterYellowOrage } from '../fontStyles';



export default class ModalPaytable extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.visible = false;
    this.slides = [];
    this.currentSlide = 0;
    for (let i = 0; i < 3; i++) {
      const slide = this.getChildByName(`slide_${i}`);
      slide.visible = i === this.currentSlide;
      const winText = slide.getChildByName('paytable_0_1')
      if (winText) winText.style = {...winText.style, ...yellowLighterYellowOrage};
      this.slides.push(slide);
    }

    const buttons = this.getChildByName('buttons');
    const buttonClose = buttons.getChildByName('buttonClose');
    const buttonPrev = buttons.getChildByName('buttonPrev');
    const buttonNext = buttons.getChildByName('buttonNext');

    buttonClose.enabled = buttonPrev.enabled = buttonNext.enabled = true;
    buttonClose.on('pointertap', this.onCloseClicked, this);
    buttonPrev.on('pointertap', this.onPrevClicked, this);
    buttonNext.on('pointertap', this.onNextClicked, this);

    GlobalDispatcher.add('ui:showPaytable', () => this.visible = true, this);
    GlobalDispatcher.add('ui:togglePaytable', () => this.visible = !this.visible);
  }

  onPrevClicked() {
    this.slides[this.currentSlide].visible = false;
    this.currentSlide = (3 + this.currentSlide - 1) % 3;
    this.slides[this.currentSlide].visible = true;
  };

  onNextClicked() {
    this.slides[this.currentSlide].visible = false;
    this.currentSlide = (this.currentSlide + 1) % 3;
    this.slides[this.currentSlide].visible = true;
  };

  onCloseClicked() {
    this.visible = false;
  }
}
