import './import';
import * as PIXI from 'pixi.js';
// import '../css/index.css'
import 'pixi-spine';
// import './libs/fullscreen/fullscreen';
import Game from './Game';
import {requestConfig} from "./api/rest";
import OPWrapperService from "./libs/op-wrapper-service";
import './libs/coin/src/js/index';
import wrapperConfig from './wrapperConfig';
// import './libs/fullscreen/fullscreen';

// redirect to game launch error page  if it is Inplaynet casino
if (window.OPUtility.urlParser.getParam('pid') === '640' && window.OPUtility.urlParser.getParam('casinoId') === 'Inplaynet') {
  location.href = 'https://int.dev.onlyplay.net/html/operator_game_launch_error.html?logstring=';
}

new OPWrapperService(wrapperConfig);

requestConfig().then(data => new Game(data));

