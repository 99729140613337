import en from '../assets/i18n/en';
import enSocial from '../assets/i18n/en-social';

export default {
  bundle: 'thimbles',
  localizationBundle: 'thimbles',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
