import FlashLib from "flashlib";
import GameModel from "../models/GameModel";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import Character from "./Character";
import GameEvents from "../data/events";



export default class BackgroundController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this._characterSlot = null;
    this._characters = new Map();
    this._currentCharacter = null;

    this.init();
  }

  init() {
    this._characterSlot = this.getChildByName('character_slot');
    this._characterSlot.y -= 280;
    this._characterSlot.x -= 100;

    GameModel.characters.forEach(char => {
      const character = new Character(char);
      character.hide();

      this._characterSlot.addChild(character.animation);
      this._characters.set(char, character);
    });

    const mexican = this._characters.get('diablo');
    mexican.animation.y += 130;
    mexican.animation.scale.set(0.7);

    const pirate = this._characters.get('pirate');
    pirate.animation.scale.set(0.68);
    pirate.animation.y += 50;

    const prisoner = this._characters.get('prisoner');
    prisoner.animation.scale.set(0.7);
    prisoner.animation.y += 48;

    const asian = this._characters.get('asia');
    asian.animation.scale.set(0.85);
    asian.animation.y -= 124;

    GlobalDispatcher.add(GameEvents.UI.MUTE_BG_SOUND, this.onSoundMute, this);
    GlobalDispatcher.add(GameEvents.CHARACTER.CHANGE_HERO, this.updateView, this);
    GlobalDispatcher.add(GameEvents.GAME.WIN, this.onGameWin, this);

    const index = GameModel.bonus_game.active_hero;
    const name = GameModel.characters[index];
    this.updateView({params: { name, index }});
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.CHARACTER.CHANGE_HERO, this.updateView, this);
    GlobalDispatcher.remove(GameEvents.UI.MUTE_BG_SOUND, this.onSoundMute, this);
  }

  onSoundMute({params}) {
    params ? this._currentCharacter.stopSound() : this._currentCharacter.playSound();
  }

  updateView({params}) {
    this.goToFrame(params.name);

    if(this._currentCharacter) {
      this._currentCharacter.hide();
      this._currentCharacter.stopSound();
    }

    const character = this._characters.get(params.name);
    character.show();
    character.playSound();

    this._currentCharacter = character;
  }

  onGameWin({params}) {
    params ? this._currentCharacter.loose() : this._currentCharacter.win();
  }

  getFrameIndexByName(name, layerIndex = 0) {
    return this.timelineData.layers[layerIndex].frames.find(frame => frame.name === name).index + 1;
  }

  goToFrame(frameId, force = false) {
    if(typeof frameId === 'string') {
      frameId = this.getFrameIndexByName(frameId, 0);
    }

    super.goToFrame(frameId);

    this._personIconController = this.children[0];
  }

}