import BaseButton from "../libs/game-engine/src/base/ui/BaseButton";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameEvents from "../data/events";

export default class MaxBetButtonController extends BaseButton {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.enabled = true;
  }

  init() {
    const text = this.getChildByName('btn_text_max_bet');
    const saveCorrectPositionFunction = text.correctPosition.bind(text);
    text.correctPosition = () => {
      saveCorrectPositionFunction('center', 'center');
    }
    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiDisabled, this);
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.UI.ENABLED, this.onUiDisabled, this);
  }

  onClick() {
    super.onClick();
    GlobalDispatcher.dispatch(GameEvents.UI.SET_MAX_BET);
  }

  onUiDisabled({params}) {
    const isFreeBets = window.OPWrapperService.freeBetsController.isActive
      || window.OPWrapperService.freeBetsController.isFirstFreeBet;
    this.enabled = !isFreeBets && params;
  }
}
