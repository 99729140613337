import FlashLib from 'flashlib';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import GameEvents from '../data/events';
import GameModel from '../models/GameModel';

export default class GetKeyPopupController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.visible = false;

    this._exitButton = null;
    this._prevKeys = 0;

    this.init();
  }

  init() {
    this._exitButton = this.getChildByName('button_popup');
    this._exitButton.enabled = true;
    this._exitButton.onClick = () => this.visible = false;

    this._prevKeys = GameModel.bonus_game.keys;

    GlobalDispatcher.add('bonus:close', () => this._prevKeys = 0);
    GlobalDispatcher.add(GameEvents.UI.OPEN_KEY_POPUP, () => {

      if(GameModel.bonus_game.keys !== this._prevKeys) {
        this.visible = true;
        this._prevKeys = GameModel.bonus_game.keys;
      }
    }, this);

  }

}