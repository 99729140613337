import FlashLib from 'flashlib';
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import {startGame, startGameCheatingGame} from "../api/rest";
import GameModel from "../models/GameModel";
import {getRandomInt} from "../utils/random";
import {sleep} from "../utils/sleep";
import ControllerSounds from '../sounds/controllerSounds';
import { eSoundType } from "../sounds/controllerSounds";
import GameEvents from "../data/events";



export default class ThimbleGroupController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this._thimbles = [];
    this._thimblesPositions = [];
    this._initialX = 0;

    this.init();
  }

  init() {
    this._initialX = this.x;

    GlobalDispatcher.add(GameEvents.THIMBLE.COUNT_CHOOSED, this.onThimbleCountChosed, this);
    GlobalDispatcher.add(GameEvents.THIMBLE.PICKED, this.onThimblePicked, this);
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.THIMBLE.COUNT_CHOOSED, this.onThimbleCountChosed, this);
    GlobalDispatcher.remove(GameEvents.THIMBLE.PICKED, this.onThimblePicked, this);
  }

  async onThimbleCountChosed({ params }) {

    if(GameModel.isRestoredBaseGame || GameModel.isRestoredCoin) {
      GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, false);
      GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);
      GlobalDispatcher.dispatch(GameEvents.GAME.END, {is_win: true, win_amount: GameModel.win_amount});

      for(let i = 0; i < params; i++) {
        const thimble = this.createThimble();
        this._thimbles.push(thimble);
      }

      this._thimblesPositions = this.generatePositions();

      await this.setDiceToRandomThimble();
      await this.onGameStarted();
      this._thimbles.forEach(thimble => thimble.enable());

      GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, true);

      GameModel.isRestoredCoin = false;

      if(!GameModel.isRestoredCoin && !GameModel.isRestoredBaseGame) {
        GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);
      }

      if(GameModel.round > GameModel.bonus_game.limits[GameModel.thimbles]) {
        GlobalDispatcher.dispatch(GameEvents.UI.OPEN_COIN_POPUP);
      }

      GameModel.isRestoredBaseGame = false;

      return;
    }

    GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, false);
    GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);
    this.destroyThimbles();

    for(let i = 0; i < params; i++) {
      const thimble = this.createThimble();
      this._thimbles.push(thimble);
    }

    this._thimblesPositions = this.generatePositions();

    await this.setDiceToRandomThimble();
    await this.onGameStarted();
    this._thimbles.forEach(thimble => thimble.enable());

    GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, true);

  }

  async setDiceToRandomThimble() {
    const index = getRandomInt(0, this._thimbles.length);
    const thimble = this._thimbles[index];

    this._thimbles.forEach(thimble => thimble.disable());

    thimble.openWithDice(true);
    await sleep(1000);
    thimble.closeWithDice(true);
    await sleep(1000);
  }

  async onGameStarted() {
    GlobalDispatcher.dispatch(GameEvents.GAME.START);
    await this.shuffleThimbles(9);
  }

  async openThimble(thimble) {
    if (
      GameModel.round === 1
      && GameModel.balance < GameModel.betAmount
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    ) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    } else if ( GameModel.round === 1) {
      if (window.OPWrapperService.freeBetsController.isActive
        || window.OPWrapperService.freeBetsController.isFirstFreeBet) {
        window.OPWrapperService.freeBetsController.decreaseFreeBets();
        window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
      } else {
        window.OPWrapperService.ControllerStatistic.increaseSpinCount();
      }
      OPWrapperService.ControllerStatistic.bet = GameModel.betAmount;
    }
    OPWrapperService.realityCheck.blockedRealityCheck = true;

    GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, false);
    GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);

    this._thimbles.forEach(t => t.disable());

    // const response = await startGame(GameModel.thimbles, thimble.index, GameModel.pickedBetIndex, GameModel.bonus_game.active_hero);
    const response = await startGameCheatingGame(GameModel.thimbles, thimble.index, GameModel.pickedBetIndex, GameModel.bonus_game.active_hero);
    // if(response.code && response.code === 5005) {
    //   OPWrapperService.showMessage('not enough money', response.message, true);
    // }

    const diceIndex = response.thimbles.indexOf(1);

    const sound = thimble.index === diceIndex ? eSoundType.WIN : eSoundType.LOOSE;
    ControllerSounds.playSound(sound);
    thimble.openWithDice(thimble.index === diceIndex);

    await sleep(1000);
    const thimbles = this._thimbles.filter(t => t.id != thimble.id);

    GameModel.change({
      balance: response.balance,
      win_amount: response.win_amount,
      is_win: response.is_win,
      is_finished: response.is_finished,
      is_minigame_available: response.is_minigame_available || false,
      round: response.round || 0,
      bonus_game: { ...GameModel.bonus_game, ...response.bonus_game },
      pickedBetIndex: response.bet_index || GameModel.pickedBetIndex,
      betAmount: (response.bet_index && GameModel.bets_available[response.bet_index]) || GameModel.betAmount,
      is_bonus_game_available: response.is_bonus_game_available
    });

    GameModel.parseFreeBets(response);

    GlobalDispatcher.dispatch(GameEvents.GAME.WIN, response.is_win);
    GlobalDispatcher.dispatch(GameEvents.GAME.END, response);
    thimbles.forEach(t => t.openWithDice(t.index === diceIndex));

    const activeHero = GameModel.bonus_game.active_hero;
    const progressBar = GameModel.bonus_game.progress_bars[activeHero];
    GlobalDispatcher.dispatch(GameEvents.CHARACTER.UPDATE_PROGRESS_BAR, {progress: progressBar, index: activeHero});

    await sleep(1000);

    this._thimbles.forEach(t => t.closeWithDice(t.index === diceIndex));
    window.OPWrapperService.freeBetsController.show();
    if (!GameModel.is_win) OPWrapperService.realityCheck.blockedRealityCheck = false;
  }

  async onThimblePicked(event) {
    if (
      GameModel.round === 1
      && GameModel.balance < GameModel.betAmount
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    ) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    await this.openThimble(event.params);
    await sleep(1000);
    await this.onGameStarted();
    this._thimbles.forEach(thimble => thimble.enable());

    if(GameModel.is_win) {
      GlobalDispatcher.dispatch(GameEvents.UI.OPEN_KEY_POPUP);
    }

    if(GameModel.is_win && !GameModel.is_finished) {
      GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, true);
    }
    else {
      GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, true);
      window.OPWrapperService.freeBetsController.updateTotalWin();
    }
  }

  createThimble() {
    const thimble = FlashLib.createItemFromLibrary(
        'movieClips/thimble',
        'FlashLib'
    );

    this.addChild(thimble);
    return thimble;
  }

  generatePositions() {
    if(!this._thimbles.length) return;

    const thimblesCount = this._thimbles.length;
    const thimbleWidth = this._thimbles[0].width;
    const thimblesWidth = thimblesCount * thimbleWidth;
    const startPositionX = (this.width - thimblesWidth / 2) - this.width / 2;

    // TODO: Quick fix REMOVE ASAP
    if(this._thimbles.length === 3) {
      this.x = this._initialX - 41;
    }
    if(this._thimbles.length === 4) {
      this.x = this._initialX - 11;
    }
    if(this._thimbles.length === 5) {
      this.x = this._initialX + 10;
    }

    return this._thimbles.map((thimble, index) => {
      const coef = parseInt(Math.abs((index - thimblesCount / 2) + 0.5)); // Sorry @_@
      const x = startPositionX + thimble.width * index;
      const y = thimble.y + (190 - coef * 30);
      const scale = thimble.scale.x - (coef / 10);

      // TODO: Remove from here
      thimble.x = x - (50 * index);
      // thimble.x = x;
      thimble.y = y;
      thimble.scale.set(scale);
      thimble.id = index;
      thimble.index = index;

      return {x, y, scale};
    });
  }

  async shuffleThimbles(iterations) {
    ControllerSounds.playSound(eSoundType.THIMBLE_SHUFFLE);

    for(let i = 0; i < iterations; i++) {
      const step = this._thimbles.length + 1;
      const currentThimbleIndex = i % this._thimbles.length;
      const nextThimbleIndex = (currentThimbleIndex + step) % this._thimbles.length;
      const thimble = this._thimbles[currentThimbleIndex];
      const nextThimble = this._thimbles[nextThimbleIndex];

      const cachedThimbleData = {x: thimble.x, y: thimble.y, scale: thimble.scale.x, index: thimble.index};
      const delta = currentThimbleIndex < nextThimbleIndex ? 1 : -1;

      const thimbleOrder = delta < 0 ? this._thimbles.length : 0;
      const nextThimbleOrder = thimbleOrder === 0 ? this._thimbles.length : 0;

      this.addChildAt(thimble, thimbleOrder);
      this.addChildAt(nextThimble, nextThimbleOrder);

      thimble.moveTo(nextThimble.x, nextThimble.y, nextThimble.scale.x, delta);
      await nextThimble.moveTo(cachedThimbleData.x, cachedThimbleData.y, cachedThimbleData.scale, -delta);

      thimble.index = nextThimble.index;
      nextThimble.index = cachedThimbleData.index;
    }
  }

  destroyThimbles() {
    if(!this._thimbles.length) return;
    this._thimbles.forEach(thimble => thimble.destroy());
    this._thimbles.length = 0;
  }

}
