import FlashLib from "flashlib";
import {chestValueStyle} from "../ui/textStyles";
import animationsLoader from "../animations/animationsLoader";
import eAnimationType from "../animations/eAnimationType";

const eAnimationName = {
  IDLE: 'idle',
  IDLE_PLAYER_CHOISE: 'idle_chest_player_choise',
  PENDING: 'pendind_chest',
  PENDING_SILENT: 'pendind_chest_silent',
  START: 'start_chest',
  START_PLAYER_CHOISE: 'start_chest_players_choise'
};

export default class ChestController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.index = 0;

    this.init();
  }

  init() {
    this._valueField = this.getChildByName('value');
    this._valueField.style = chestValueStyle;
    this._valueField.visible = false;
    this.setChildIndex(this._valueField, this.children.length - 1);

    this._arrow = this.getChildByName('arrow');
    this._arrow.visible = false;

    this.animation = animationsLoader.createAnimation(eAnimationType.CHEST);
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.PENDING_SILENT, true, 0);
    this.animation.scale.set(0.65);
    this.animation.y -= 70;
    this.animation.x += 25;

    this.addChild(this.animation);
    this.getChildAt(0).visible = false;

    this.enable();
    this.setupAnimationEventHandlers();
  }

  setupAnimationEventHandlers() {
    this.animation.state.addListener({
      complete: entry => {
        if(entry.animation.name === eAnimationName.START_PLAYER_CHOISE) {
          this.setChildIndex(this._valueField, this.children.length - 1);
          this._valueField.visible = true;
          this.animation.state.addAnimation(0, eAnimationName.IDLE_PLAYER_CHOISE, true, 0);
        }
        if(entry.animation.name === eAnimationName.START) {
          this.setChildIndex(this._valueField, this.children.length - 1);
          this._valueField.visible = true;
          this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);
        }
      }
    });
  }

  destroy() {
    super.destroy();
  }

  enable() {
    this.interactive = true;
    this.cursor = 'pointer';
    this._arrow.visible = true;
  }

  disable() {
    this.interactive = false;
    this.cursor = null;
    this._arrow.visible = false;
  }

  open(value, openedByPlayer = true) {
    const animationName = openedByPlayer ? eAnimationName.START_PLAYER_CHOISE : eAnimationName.START;

    this.disable();
    this._valueField.text = value.toString();
    this.animation.state.addAnimation(0, animationName, false, 0);
  }

  close() {
    this.animation.state.addAnimation(0, eAnimationName.PENDING_SILENT, true, 0);
    this._valueField.visible = false;
    this.enable();
  }

}