import FlashLib from "flashlib";

export default class BaseButton extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.state = 'out';
        this.previousState = 'out';

        this._actions = {};
        this._enabled = false;

        this.textures = [];

        this.init();
        this.addListeners();

        this.enabled = false;
    }

    init() {
        this.interactive = true;
        this.buttonMode = true;
    }

    addListeners() {
        this.on('mouseover', this.onEvent, this);
        this.on('mouseout', this.onEvent, this);
        this.on('mousedown', this.onEvent, this);
        this.on('mouseup', this.onEvent, this);
        this.on('click', this.onEvent, this);

        this.on('touchstart', this.onEvent, this);
        this.on('touchend', this.onEvent, this);

    }

    onEvent($data) {
        switch ($data.type) {
            case 'mouseover':
                this.onOver();
                break;
            case 'mouseout':
                this.onOut();
                break;
            case 'mousedown':
                this.onDown();
                break;
            case 'mouseup':
                this.onUp();
                break;
            case 'click':
                this.onClick();
                break;
            case 'touchstart':
                this.onDown();
                break;
            case 'touchend':
                this.onUp();
                this.onClick();
                break;
        }

        let actionData = this.actions[$data.type];
        if (actionData) {
            if (actionData.callback) {
                actionData.callback.call(actionData.context, actionData.params);
            }
        }
    }

    onOver() {
        this.setSate(2);
    }

    onOut() {
        this.setSate(1);
    }

    onDown() {
        this.setSate(3);
    }

    onUp() {
        this.setSate(2);
    }

    onClick() {
        //console.log(this.name, 'clicked')
    }

    setSate(frameIndex) {
        let state = ''
        switch (frameIndex) {
            case 1:
                state = 'out';
                break;
            case 2:
                state = 'over';
                break;
            case 3:
                state = 'down';
                break;
            case 4:
                state = 'disabled';
                break;
        }
        if(state !== '') {
            this.previousState = this.state;
            this.state = state;
        }
        this.goToFrame(frameIndex);
    }

    get actions() {
        return this._actions;
    }

    set actions(value) {
        this._actions = value;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value;
        if (value) {
            this.setSate(this.previousState === 'over' ? 2 : 1);
            this.interactive = true;
            this.buttonMode = true;
        } else {
            this.setSate(4);
            this.interactive = false;
            this.buttonMode = false;
        }
    }
}
//FlashLib.registerClass('BaseButton', BaseButton);
