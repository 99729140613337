import './index.css';

class OrientationBlocker {
  constructor() {
    this.orientationType = {
      PORTRAIT: 'portrait',
      LANDSCAPE: 'landscape'
    };
  }

  init(type) {
    document.body.insertAdjacentHTML('beforeend',
      '<div id="orientationBlocker" class="orientationBlocker">\n' +
      `  <div class="${type}Icon"></div>\n` +
      '  <div class="sign">\n' +
      '    Please, rotate your screen\n' +
      '  </div>\n' +
      '</div>');

    this.element = document.getElementById('orientationBlocker');
    this.preferedOrientation = type;

    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation.bind(this));
    window.addEventListener('orientationchange', this.checkOrientation.bind(this));
  }

  checkOrientation() {
    const el = document.documentElement;
    // console.log(el.clientWidth, el.clientHeight);
    if (el.clientWidth < el.clientHeight && this.preferedOrientation === this.orientationType.LANDSCAPE) {
      this.element.classList.add('show');
    } else if (el.clientWidth > el.clientHeight && this.preferedOrientation === this.orientationType.PORTRAIT) {
      this.element.classList.add('show');
    } else {
      this.element.classList.remove('show');
    }
  }
}

export default new OrientationBlocker();
