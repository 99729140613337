import { whiteOceanGradientWithBlueShadow } from './fontStyles';

export default class ControllerUI {
  constructor(main, container) {
    this.main = main;
    this.container = container;
    const title = main.getChildByName("submodule_coin_title");
    title.style = {
      ...title.style,
      ...whiteOceanGradientWithBlueShadow,
      textBaseline: 'middle'
    };

    this.topPanel = main.getChildByName('content_top');
    this.buttonBet = main.getChildByName('button_bet');
    this.coinView = main.getChildByName('coin_view');
    this.buttonClose = main.getChildByName('button_close');

    this.buttonBet.on('pointertap', this.buttonBetClicked.bind(this));
    this.buttonClose.on('pointertap', () => this.container.style.display = 'none');
    this.topPanel.addListenerButtonClick(this.buttonCollectClicked.bind(this));
  }

  show(minBetAmount, amount, currency, onPlayClicked, onCollectClicked, canTake) {
    this.container.style.display = 'flex';
    this.main.visible = true;
    this.betAmount = amount;
    this.minBetAmount = minBetAmount * 2;
    this.currency = currency;

    this.coinView.init();

    if(canTake) {
      this.topPanel.showCollect(amount, currency);
    } else {
      this.topPanel.showStart(amount, currency);
    }
    this.buttonBet.show(amount * 2, currency);
    this.onPlayClicked = onPlayClicked;
    this.onCollectClicked = onCollectClicked;
    this.buttonClose.visible = !canTake;
    this.buttonBet.enabled = this.minBetAmount <= amount;
  }

  buttonBetClicked() {
    //send request here
    this._enableUI(false);

    this.coinView.startAnimation();
    this.buttonClose.visible = false;
    this.onPlayClicked();
  }

  showResult(isWin, winAmount) {
    this.coinView.stopAnimation(isWin);
    this.coinView.onStopCompleteOnce = this.onStopAnimationComplete.bind(this);
    this.betAmount = winAmount;
  }

  buttonCollectClicked() {
    this.onCollectClicked();
    this.container.style.display = 'none';
  }

  onStopAnimationComplete() {
    this._enableUI(true);
    this.topPanel.showCollect(this.betAmount, this.currency);
    this.buttonBet.show(this.betAmount * 2, this.currency);

    if (this.betAmount < this.minBetAmount) {
      this.buttonBet.enabled = false;
    }
  }

  _enableUI(value) {
    this.buttonClose.enabled = value;
    this.buttonBet.enabled = value;
    this.topPanel.enable(value);
  }
}
