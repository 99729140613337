import FlashLib from "flashlib";
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import GameModel from '../models/GameModel';



export default class TurnPanelController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this._valueField = null;
    this._jackpotAnimationSlot = null;

    this.init();
  }

  init() {
    GameModel.subscribe(gameModel => {
      this.setAnimationSpeed(gameModel.round);
    });
  }

  setAnimationSpeed(round) {
    if(!this.animation) return;

    this.animation.state.timeScale = 1 + (round * 3) / 10;
  }

  setupAnimation() {
    const animation = animationsLoader.createAnimation(eAnimationType.JACKPOT_PANEL);
    animation.state.clearTracks();
    animation.skeleton.setToSetupPose();
    animation.state.addAnimation(0, 'animation', true, 0);
    animation.y -= 75;
    animation.x -= 10;

    return animation;
  }

  setValue(value) {
    this._valueField.text = `X${value.toString()}`;
  }

  setActive() {
    this.goToFrame(3);
    this._valueField = this.getChildByName('value');
  }

  setDisabled() {
    this.goToFrame(1);
    this._valueField = this.getChildByName('value');
  }

  setPast() {
    this.goToFrame(2);
    this._valueField = this.getChildByName('value');
  }

  setCashValue(value) {
    const jackpot = this.getChildByName('jackpot')
    if(!jackpot) return;

    jackpot.text = value.toString();
  }

  setLastPanelFrame() {
    if(this.animation) return;
    this.goToFrame(4);

    this._valueField = this.getChildByName('value');
    this._jackpotAnimationSlot = this.getChildByName('jackpot_panel');

    const currencyPanel = this.getChildByName('currency');
    currencyPanel.text = GameModel.currency.toUpperCase();

    this._jackpotAnimationSlot.children.length = 0;
    this.animation = this.setupAnimation();
    this.setAnimationSpeed(GameModel.round);
    this._jackpotAnimationSlot.addChild(this.animation);
  }

}
