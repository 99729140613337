// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/portrait.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/landscape.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".orientationBlocker {\n  background: black;\n  position: absolute;\n  z-index: 20;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  display: none;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -ms-flex-direction: column;\n  flex-direction: column;\n  -webkit-box-pack: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n}\n\n.show {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.portraitIcon {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  width: 33vw;\n  height: 60vh;\n}\n\n.landscapeIcon {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center;\n  width: 60vw;\n  height: 33vh;\n}\n\n/*todo: import font from assets*/\n.sign {\n  font-family: \"Ubuntu bold\", sans-serif;\n  text-align: center;\n  color: white;\n}", ""]);
// Exports
module.exports = exports;
