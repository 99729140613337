import GlobalDispatcher from '../../libs/game-engine/src/events/GlobalDispatcher';
import GameEvents from '../../data/events';
import GameModel from '../../models/GameModel';
import controllerSounds from '../../sounds/controllerSounds';

export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    GlobalDispatcher.add(GameEvents.BET.CHANGED, this.stakeUpdate, this);
    GlobalDispatcher.add(GameEvents.THIMBLE.PICKED, this.roundStart, this)
    GlobalDispatcher.add(GameEvents.GAME.END, this.roundEnd, this)
    GlobalDispatcher.add(GameEvents.SOUNDS_TOGGLE, this.audioToggle, this);
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {

  }

  setAudio(value) {
    controllerSounds.setSoundsMuted(!value);
  }

  showHelp() {

  }

  togglePaytable() {
    GlobalDispatcher.dispatch('ui:togglePaytable');
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
    this.audioToggle();
    this.stakeUpdate();
  }

  audioToggle() {
    const audioState = !controllerSounds.soundsMuted;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
  }

  stakeUpdate() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, GameModel.betAmount);
  }

  roundStart() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START);
  }

  roundEnd() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END);
  }

  closed() {

  }

  errorMessage(data) {
    const message = {
      errorCode: '8501',
      errorMessage: data.params.message
    };
    //EveryMatrix.errorMessage(message);
  }
}
