import FlashLib from "flashlib";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameModel from "../models/GameModel";

export default class BonusPopupController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.visible = false;
    this.interactive = true;
    this._winWalue = null;

    this.init();
  }

  init() {
    GlobalDispatcher.add('bonus:close', this.close, this);
    GlobalDispatcher.add('bonus:collect', this.open, this);
    this._winWalue = this.getChildByName('value');
  }

  open({params}) {
    this.visible = true;
    this._winWalue.text = params.win_amount.toString();
  }

  close() {
    this.visible = false;
    GlobalDispatcher.dispatch('chest:cloaseAll');
    GameModel.bonus_game.opened_chests = null;
    GameModel.change({isBonusGame: false});
  }

}