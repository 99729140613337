import FlashLib from "flashlib";
import {TimelineMax} from "gsap/TweenMax";

export default class ArrowController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);
    this.init();
  }

  init() {
    const y = this.y - 30;
    this._arrowAnimation = new TimelineMax({repeat: -1});
    this._arrowAnimation.to(this, 0.5, { y }).yoyo(true);
  }

  destroy() {
    super.destroy();
    this._arrowAnimation.stop();
  }

}