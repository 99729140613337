import FlashLib from 'flashlib';
import GameModel from '../models/GameModel';
import { formatCurrency } from '../utils/currency';

export default class BalanceController extends FlashLib.MovieClip {

  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.init();
  }

  init() {
    GameModel.subscribe(this.updateBalance, this);
  }

  destroy() {
    super.destroy();
    GameModel.unsubscribe(this.updateBalance, this);
  }

  updateBalance(gameModel) {
    const field = this.getChildByName('balance_value');
    field.text = `${formatCurrency(gameModel.balance, gameModel.decimal)} ${GameModel.currency.toUpperCase()}`;
  }

}
