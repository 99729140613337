export default {
  THIMBLE: 'thimble',
  CHEST: 'chest',
  BONUS_BUTTON: 'bonus_button',
  PRISONER: 'prisoner',
  PIRATE: 'pirate',
  MEXICAN: 'diablo',
  ASIAN: 'asia',
  JACKPOT_PANEL: 'jackpot_panel',
  COIN_ICON: 'coin_icon'
}
