import FlashLib from 'flashlib';
import { collect } from '../api/rest';
import GameModel from '../models/GameModel';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import GameEvents from '../data/events';
import { epicWinValue } from '../fontStyles';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';



export default class CoinPopupController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.visible = false;
    this.interactive = true;

    this._doubleButton = null;
    this._cancelButton = null;
    this._winValueField = null;
    this._winValue = null;
    this._winValuePositionX = 0;

    this.init();
  }

  init() {
    this._winValueField = this.getChildByName('win_value');
    this._winValueField.text = '';
    this._winValuePositionX = this._winValueField.x;
    this._winValue  = new PIXI.BitmapText('35000', epicWinValue);
    this._winValueField.x = this._winValuePositionX - this._winValue.width / 2;

    this._winValueField.addChild(this._winValue);
    this._fontsRatio = this._winValue.font.size/this._winValueField.style.fontSize * 0.8;
    this._doubleButton = this.getChildByName('double_button');
    this._doubleButton.enabled = true;

    const currency = this.getChildByName('currency');
    currency.text = GameModel.currency.toUpperCase();

    this._doubleButton.onClick = () => {
      GlobalDispatcher.dispatch(GameEvents.COIN_MINIGAME.SHOW);
    }

    this._cancelButton = this.getChildByName('no_thank_you');
    this._cancelButton.interactive = true;
    this._cancelButton.cursor = 'pointer';

    this._cancelButton.on('pointertap', async () => {
      const response = await collect();

      GameModel.change({
        ...GameModel,
        ...response
      });

      ControllerSounds.playSound(eSoundType.COIN_COLLECT);
      GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, true);
      GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);
      window.OPWrapperService.freeBetsController.updateTotalWin();
      this.visible = false;
    }, this);

    GlobalDispatcher.add(GameEvents.UI.OPEN_COIN_POPUP, this.onPopupOpen, this);
    GlobalDispatcher.add(GameEvents.UI.CLOSE_COIN_POPUP, this.onPopupClose, this);
    GlobalDispatcher.add(GameEvents.THIMBLE.COUNT_CHOOSED, this.onThimblesCountChange, this);
    GlobalDispatcher.add(GameEvents.BET.CHANGED, this.onThimblesCountChange, this);
  }

  onPopupOpen() {
    this.visible = true;

    if(GameModel.isRestoredCoin) {
      this._winValue.text = GameModel.win_amount;
    }
  }

  onPopupClose() {
    this.visible = false;
  }

  onThimblesCountChange() {
    this._winValue.text = GameModel.bonus_game.limits_payout[GameModel.thimbles] * GameModel.betAmount;
    this._winValueField.text = GameModel.bonus_game.limits_payout[GameModel.thimbles] * GameModel.betAmount;
    this._winValueField.fitSize(true, true);
    this._winValue.font = { ...this._winValue.font, size: this._winValueField.style.fontSize * this._fontsRatio} ;
    this._winValueField.text = '';
    this._winValueField.x = this._winValuePositionX - this._winValue.width / 2;

  }

}
