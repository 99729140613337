import '../scss/styles.scss';
import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib';

import * as queryString from 'query-string'

import '../css/index.css'

// import { requestConfig } from '../api/rest';
import WebFont from 'webfontloader';
// import Preloader from '../libs/preloader/preloader';
import ControllerSounds from './sounds/controllerSounds';
import AnimationsLoader from './animations/animationsLoader';
// import ControllerStartScreen from './controllerStartScreen';

import OrientationBlocker from './libs/orientationBlocker'

import assetsListDesktop from '../assets/flashlib/exported/desktop/AssetsCombined.json';
import flashLibMetaDesktop from '../assets/flashlib/exported/desktop/FlashLib';
PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

import GameModel from "./models/GameModel";
import './CoinMinigame.js';
import EMAdapter from './platforms/everyMatrix/adapter';
import ControllerTextField from './textField/ControllerTextField';
import Config from './Config';

export default class Game {
  constructor(serverConfig) {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.configReceived = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;

    this.config = {
      width: 1917,
      height: 1080,
      ...serverConfig
    };

    GameModel.decimal = serverConfig.decimal;
    if (serverConfig.hasOwnProperty('bet_index')) GameModel.pickedBetIndex = serverConfig.bet_index;

    EMAdapter.init();

    this.start();
  }

  async start() {
    const defaultCharacter = ['asia', 'diablo', 'prisoner', 'pirate'];
    const checkCorrectCharactersArray = (charactersArray) => Array.isArray(charactersArray) && charactersArray.length === 4 && charactersArray.every((character) => defaultCharacter.includes(character));


    const partnerConfigCharacters = OPWrapperService.config.gameConfig && OPWrapperService.config.gameConfig.characters;
    const isCorrectPartnerCharactersArray = checkCorrectCharactersArray(partnerConfigCharacters);
    if (partnerConfigCharacters && !isCorrectPartnerCharactersArray) console.warn('Not correct characters array in partner config');

    const search = queryString.parse(location.search);
    const urlCharacters = search.characters && JSON.parse(search.characters)
    const isCorrectUrlCharactersArray = checkCorrectCharactersArray(urlCharacters)
    if (urlCharacters && !isCorrectPartnerCharactersArray) console.warn('Not correct characters array in url parameters')

    const characters = isCorrectPartnerCharactersArray && partnerConfigCharacters ? partnerConfigCharacters : isCorrectUrlCharactersArray && urlCharacters ? urlCharacters : defaultCharacter;

    GameModel.change({
      ...this.config,
      characters: characters,
    });
    GameModel.setBets(this.config.bets_available);

    // Restore game
    if(this.config.round > 1 && this.config.game_type === 'base') {
      GameModel.isRestoredBaseGame = true;
      GameModel.thimbles = this.config.game_mode;
      GameModel.win_amount = this.config.win_amount;
      GameModel.betAmount = this.config.starting_bet;
      GameModel.pickedBetIndex = GameModel.bets.indexOf(this.config.starting_bet);
    }

    if(this.config.game_type === 'minigame') {
      GameModel.isRestoredCoin = true;
      GameModel.win_amount = this.config.win_amount;
      GameModel.thimbles = this.config.game_mode;
      GameModel.betAmount = this.config.starting_bet;
      GameModel.pickedBetIndex = GameModel.bets.indexOf(this.config.starting_bet);
    }

    GameModel.parseFreeBets(this.config)

    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0x6fc1f6,
      // legacy: true,
      transparent: true,
      width: 0,
      height: 0,
      resolution: 1,
      // autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);

    FlashLib.TextField = ControllerTextField;


    this.scaleData = {
      app: this.app,
      scaleRatio: 2,
      gameSize: this.config
    };

    document.getElementById('container').appendChild(this.app.view);
    this.app.view.id = 'gameCanvas';

    this.loadAssets();
    this.loadFonts();

    ControllerSounds.startLoading(() => {
        this.soundsLoaded = true;
        this.tryInitGame();
    });
    //
    AnimationsLoader.startLoading(this.app, () => {
        console.log('Animations loaded');
        this.animationsLoaded = true;
        this.tryInitGame();
    });
    //
    // requestConfig()
    //     .then(() => {
    //         console.log('Config received');
    //         this.configReceived = true;
    //         this.tryInitGame();
    //     });
  }

  loadAssets() {
    PIXI.Loader.shared.baseUrl = './';
    PIXI.Loader.shared.add('FlashLib', flashLibMetaDesktop, 'json');
    PIXI.Loader.shared.add('FlashLibAssets', assetsListDesktop, 'json');
    PIXI.Loader.shared.add('epicFont', 'assets/fonts/epicFont.xml', 'font');

    PIXI.Loader.shared.once('complete', () => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    }, this);

    PIXI.Loader.shared.on('progress', (data) => {
      OPPreloader.setProgress(data.progress);
      EMAdapter.loadProgress(data.progress);
    });
    PIXI.Loader.shared.on('error', this.onLoadingError.bind(this));
    PIXI.Loader.shared.load();
  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: ['League Gothic Regular', 'Elanitcstd', 'Ubuntu bold'],
      },
      fontinactive: console.error,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }

  tryInitGame() {
    if(this.assetsLoaded && this.soundsLoaded && this.animationsLoaded && this.fontsLoaded) {
      this.initGame();
      OrientationBlocker.init(OrientationBlocker.orientationType.LANDSCAPE);
      window.OPPreloader.hide();
    }

  }

  initGame() {
    EMAdapter.loadCompleted();
    const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    this.app.stage.addChild(main);
    this.mainContainer = main;
    GameModel.notify();
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config);
  }
}
