// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/LEAGUEGOTHIC.TTF");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/ELANITCSTD-BLACK.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/UBUNTU-B.TTF");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/UBUNTU-R.TTF");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/Inter-ExtraBold.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'League Gothic Regular';\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n@font-face {\n  font-family: 'Elanitcstd';\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n@font-face {\n  font-family: 'Ubuntu Bold';\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n@font-face {\n  font-family: 'Ubuntu regular';\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'InterExtraBold';\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  font-weight: normal;\n  font-style: normal;\n}\n", ""]);
// Exports
module.exports = exports;
