export const whiteOceanGradientWithBlueShadow = {
  dropShadow: true,
  dropShadowAngle: 1.6,
  dropShadowColor: '#0213e9',
  dropShadowDistance: 10,
  fill: [
    '#b2f5f3',
    'white',
    '#b2f5f3'
  ],
  fillGradientStops: [
    0.5,
    0.9,
    1
  ],
  padding: 40,
  stroke: '#3711c5',
  strokeThickness: 10
};

export const blueWithDarkBlueStroke = {
  fill: "#bdc5fa",
  lineJoin: "round",
  dropShadowDistance: 6,
  strokeThickness: 4
};
