import GameModel from '../models/GameModel';

const URL = '/game_request';
import { getUrlParams } from '../utils/url';

const urlParams = getUrlParams(window.location.search);

const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
if (devEnvironments.some(regexp => regexp.test(window.location.origin))) {
  if (!Object.keys(urlParams).length) {
    getSession();
  }
}
let responseInit = null;

export const requestConfig = async () => {
  responseInit = await request('get', '/game_init');
  if (!responseInit) return;

  await window.OPWrapperService.init({
    partnerConfig: responseInit.partner_config,
    language: responseInit.lang,
    currencyInfo: {
      currency: responseInit.currency,
      decimals: responseInit.decimals,
    },
  });
  window.OPWrapperService.freeBetsController.setData(responseInit.freebets);

  const params = {
    id: 'config',
  };

  return await request('post', URL, params).catch(console.error);
};

export const startGame = async (mode, thimble, betIndex, hero) => {
  const params = { id: 'roll', mode, betIndex, thimble, hero };
  const response = await request('post', '/game_request', params);
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response
};

window.alwaysWin = 0;

export const startGameCheatingGame = async (mode, thimble, betIndex, hero) => {
  const params = { id: 'roll', debug_win: window.alwaysWin, mode, betIndex, thimble, hero };
  const response = await request('post', `/game_request`, params);
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response;
};

export const getBonusGame = async (chest = 0) => {
  const response = await request('post', '/game_request', { id: 'bonus', chest });
  OPWrapperService.ControllerStatistic.win = response.win_amount;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response;
};

export const getKeys = async () => {
  return await request('post', '/game_request', { id: 'debug' });
};

export const requestCoinMinigame = async () => {
  const response = await request('post', '/game_request', { id: 'minigame' });
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  return response;
};

export const collect = async () => {
  const response  =await request('post', '/game_request', { id: 'collect' });
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  OPWrapperService.ControllerStatistic.win = response.win_amount;
  OPWrapperService.realityCheck.blockedRealityCheck = false;
  return response;
};

window.getKeys = getKeys;
window.requestCoin = requestCoinMinigame;
window.collect = collect;

async function getSession() {
  try {
    const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=thimbles&no_redirect&no_op_launcher', {
      method: 'GET',
    });
    const response = await raw.json();
    if (response.url) {
      const searchParams = response.url.split('?')[1];
      window.location = `${window.location.origin}?${searchParams}`;
    }
  } catch (e) {
    console.error(e);
  }
}

let waitingForResponse = false;
const request = async (method, url, params) => {
  if (waitingForResponse) {
    throw new Error('Already waiting for response.')
  }

  if (!navigator.onLine) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
    throw new Error('No Internet connection.')
  }

  waitingForResponse = true;

  url = `https://${urlParams.api + url}/?sid=${urlParams.sid}&gid=${urlParams.gid}`;

  if (window.force_win) {
    params.force_win = window.force_win;
    delete window.force_win;
  }
  if (window.force_keys) {
    params.force_keys = window.force_keys;
    delete window.force_keys;
  }

  if (params) {
    params.ver = '1.1';
  }

  if (params && method === 'get')
    url += `&req=${JSON.stringify(params)}`;

  const requestConfig = { method };
  if (method === 'post') {
    requestConfig.body = JSON.stringify(params);
  }

  let response = await fetch(url, requestConfig);
  waitingForResponse = false;

  if (response.status === 500) {
    throw new Error('Server Down');
  }

  response = await response.json()
    .catch((error) => {
      console.error(error);
      console.error('Response', response);
      throw error;
    });

  if (!response.success) {
    console.error(response);
    window.OPWrapperService.showError(response.code, response.message);
    throw response;
  }
  return response;
};
