import BaseButton from "../libs/game-engine/src/base/ui/BaseButton";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameEvents from "../data/events";
import { collect } from '../api/rest';
import GameModel from '../models/GameModel';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';



export default class AutoplayButtonController extends BaseButton {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.enabled = true;
  }

  init() {
    GlobalDispatcher.add(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, this.onUiDisabled, this);
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, this.onUiDisabled, this);
  }

  onUiDisabled({params}) {
    this.enabled = params;
  }

  async onClick() {
    super.onClick();
    const response = await collect();

    GameModel.change({
      ...GameModel,
      ...response,
      pickedBetIndex: response.bet_index || GameModel.pickedBetIndex,
      betAmount: (response.bet_index && GameModel.bets_available[response.bet_index]) || GameModel.betAmount
    });

    GameModel.parseFreeBets(response);

    ControllerSounds.playSound(eSoundType.COIN_COLLECT);
    GlobalDispatcher.dispatch(GameEvents.UI.ENABLED, true);
    GlobalDispatcher.dispatch(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, false);
    window.OPWrapperService.freeBetsController.updateTotalWin();
    window.OPWrapperService.freeBetsController.show();
  }
}
