import BaseButton from '../libs/game-engine/src/base/ui/BaseButton';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import GameEvents from '../data/events';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';

const eAnimationName = {
  IDLE: 'idle'
};

export default class OpenCoinButton extends BaseButton {

  constructor(data, displayData) {
    super(data, displayData);

    this.animation = animationsLoader.createAnimation(eAnimationType.COIN_ICON);
    this.enabled = true;
  }

  init() {
    GlobalDispatcher.add(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, this.onUiDisabled, this);
    this.hitArea = new PIXI.Rectangle(-130 ,-230,260,260);
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.UI.COLLECT_NOW_BTN_ENABLED, this.onUiDisabled, this);
  }

  onUiDisabled({params}) {
    this.enabled = params;

    if(this.enabled) {
      this.animation.x = this.getChildAt(0).x + this.getChildAt(0).width / 2;
      this.animation.y = this.getChildAt(0).y + (this.getChildAt(0).height / 2) - 13;
      this.animation.visible = true;

      this.getChildAt(0).visible = false;
      this.animation.state.clearTracks();
      this.animation.skeleton.setToSetupPose();
      this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);

      if(!this.children[1]) {
        this.addChild(this.animation);
      }
      else {
        this.animation.visible = true;
      }
    }
    else {
      this.animation.visible = false;
    }
  }

  async onClick() {
    super.onClick();
    GlobalDispatcher.dispatch(GameEvents.COIN_MINIGAME.SHOW);
  }

}
