import FlashLib from "flashlib";
import GameModel from "../models/GameModel";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameEvents from "../data/events";

export default class BetSelectorController extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
  }

  init() {
    this.value = this.getChildByName('bet_value');
    this.value.text = `${GameModel.betAmount} ${GameModel.currency.toUpperCase()}`;

    this.buttonMinus = this.getChildByName('button_minus');
    this.buttonPlus = this.getChildByName('button_plus');

    this.buttonPlus.onClick = () => {

      const avaliableBets = GameModel.bets;

      if(GameModel.pickedBetIndex >= avaliableBets.length - 1) return;
      GameModel.pickedBetIndex++;
      GameModel.betAmount = GameModel.bets[GameModel.pickedBetIndex % avaliableBets.length];
      this.value.text = `${GameModel.betAmount} ${GameModel.currency.toUpperCase()}`;
      GlobalDispatcher.dispatch(GameEvents.BET.CHANGED, {index: GameModel.pickedBetIndex, amount: GameModel.betAmount})
    }

    this.buttonMinus.onClick = () => {
      const avaliableBets = GameModel.bets;

      if(GameModel.pickedBetIndex <= 0) return;
      GameModel.pickedBetIndex--;
      GameModel.betAmount = GameModel.bets[GameModel.pickedBetIndex % avaliableBets.length];
      this.value.text = `${GameModel.betAmount} ${GameModel.currency.toUpperCase()}`;
      GlobalDispatcher.dispatch(GameEvents.BET.CHANGED, {index: GameModel.pickedBetIndex, amount: GameModel.betAmount})
    }

    GlobalDispatcher.add(GameEvents.UI.SET_MAX_BET, this.onSetMaxBet, this);
    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiDisabled, this);
    GlobalDispatcher.add(GameEvents.UI.SET_BET, this.onSetBet, this)
  }

  onSetBet({params}) {
    GameModel.betAmount = GameModel.bets[params];
    this.value.text = `${GameModel.betAmount} ${GameModel.currency.toUpperCase()}`;
  }

  onSetMaxBet() {
    const maxBetIndex = GameModel.bets.length - 1;

    GameModel.pickedBetIndex = maxBetIndex;
    GameModel.betAmount = GameModel.bets[GameModel.pickedBetIndex];

    this.value.text = `${GameModel.betAmount} ${GameModel.currency.toUpperCase()}`;
    GlobalDispatcher.dispatch(GameEvents.BET.CHANGED, {index: GameModel.pickedBetIndex, amount: GameModel.betAmount})
  }

  onUiDisabled({params}) {
    const isFreeBets = window.OPWrapperService.freeBetsController.isActive
      || window.OPWrapperService.freeBetsController.isFirstFreeBet;

    this.buttonMinus.enabled = !isFreeBets && params;
    this.buttonPlus.enabled = !isFreeBets && params;
  }
}
