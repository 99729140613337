import BaseButton from "../libs/game-engine/src/base/ui/BaseButton";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import ControllerSounds from "../sounds/controllerSounds";
import GameEvents from "../data/events";
import { getFromStorage } from '../utils/localStorage';

export default class MenuButtonController extends BaseButton {
  constructor(data, displayItemData) {
    super(data, displayItemData);


    this.enabled = false;
  }

  init() {
    super.init();
    this._soundsMuted = getFromStorage('soundsMuted');

    GlobalDispatcher.add(GameEvents.UI.ENABLED, this.onUiDisabled, this);
    GlobalDispatcher.dispatch(GameEvents.UI.MUTE_BG_SOUNDMUTE_SOUND, true);

    OPWrapperService.sideBar.addButton({
      type: 'rules',
      styles: ['rules'],
      onClick: () => GlobalDispatcher.dispatch('ui:showPaytable'),
    });
    OPWrapperService.sideBar.addButton({
      type: 'sound',
      styles: [`${this._soundsMuted ? 'soundOff': 'soundOn'}`],
      onClick: () => this.onSoundMuted(),
    });
  }

  onSoundMuted() {
    this._soundsMuted = !this._soundsMuted;
    ControllerSounds.setSoundsMuted(this._soundsMuted);
    GlobalDispatcher.dispatch(GameEvents.UI.MUTE_SOUND, this._soundsMuted);
    GlobalDispatcher.dispatch(GameEvents.UI.MUTE_BG_SOUND, this._soundsMuted);
    return !this._soundsMuted
  }

  destroy() {
    super.destroy();
    GlobalDispatcher.remove(GameEvents.UI.ENABLED, this.onUiDisabled, this);
  }

  onClick() {
    super.onClick();
    OPWrapperService.toggleSidebar();
  }

  onUiDisabled({params}) {
    this.enabled = params;
  }
}
