import FlashLib from 'flashlib';
import { TimelineMax } from 'gsap/TweenMax';
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import ControllerSounds from '../sounds/controllerSounds';
import { eSoundType } from "../sounds/controllerSounds";
import animationsLoader from "../animations/animationsLoader";
import eAnimationType from "../animations/eAnimationType";
import GameEvents from "../data/events";

const eAnimationName = {
  IDLE: 'idle',
  RAISE_FULL: 'raise full',
  RAISE_EMPTY: 'raise empty',
  MOTION_BLUR_IN: 'moblur in',
  MOTION_BLUR_OUT: 'moblur out',
  CAP_EMPTY: 'cap empty',
  CAP_FULL: 'cap full'
};

export default class ThimbleController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.id = 0;
    this.index = 0;

    this.animation = animationsLoader.createAnimation(eAnimationType.THIMBLE);
    this.animation.x = this.getChildAt(0).x;
    this.animation.y = this.getChildAt(0).y - 20;

    this.getChildAt(0).visible = false;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);

    this.addChild(this.animation);

    this.init();
  }

  init() {
    this.on('pointertap', () => this.onClick());
    this.hitArea = new PIXI.Rectangle(-30, -258, 200, 280);
  }

  destroy() {
    super.destroy();
    this.off('pointertap', () => this.onClick());
  }

  onClick(event) {
    ControllerSounds.playSound(eSoundType.THIMBLE_CLICK);
    GlobalDispatcher.dispatch(GameEvents.THIMBLE.PICKED, this);
  }

  enable() {
    this.showArrow(true);
    this.interactive = true;
    this.cursor = "pointer";
    this.animation.state.timeScale = 1;
  }

  disable() {
    this.showArrow(false);
    this.interactive = false;
    this.cursor = null;

    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.MOTION_BLUR_IN, false, 0);
    this.animation.state.timeScale = 0;
  }

  showArrow(show = true) {
    if(!show) return;
    this.animation.state.timeScale = 1;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);
  }

  moveTo(x, y, scale = 1, delta = 1) {
    return new Promise((resolve, reject) => {
      const duration = 0.2;
      const values = [
        { x: this.x, y: this.y },
        { x: x / 2, y: this.y - (50 * delta) },
        { x, y }
      ];

      const animationMovement = new TimelineMax();
      animationMovement.to(this, duration, {
        bezier: { values, type: "quadratic", autoRotate: false },
        onComplete: () => resolve()
      });

      const animationScale = new TimelineMax();
      animationScale.to(this.scale, duration, { x: scale, y: scale });

      this.animation.state.timeScale = 1;
      this.animation.state.clearTracks();
      this.animation.skeleton.setToSetupPose();
      this.animation.state.addAnimation(0, eAnimationName.MOTION_BLUR_OUT, false, 0);
    });
  }

  openWithDice(hasDice = false) {
    const track = hasDice ? eAnimationName.RAISE_FULL : eAnimationName.RAISE_EMPTY;
    this.animation.state.timeScale = 1;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, track, false, 0);
    ControllerSounds.playSound(eSoundType.THIMBLE_OPEN);
  }

  closeWithDice(hasDice = false) {
    const track = hasDice ? eAnimationName.CAP_FULL : eAnimationName.CAP_EMPTY;
    this.animation.state.timeScale = 1;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, track, false, 0);
  }

  makePicked() {
    this.animation.state.timeScale = 1;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);
  }

}
