import BaseGameModel from "../libs/game-engine/src/base/model/BaseGameModel";
import GlobalDispatcher from "../libs/game-engine/src/events/GlobalDispatcher";
import GameEvents from "../data/events";

export default new class GameModel extends BaseGameModel {
  constructor() {
    super();

    this.thimbles = 0;
    this.pickedThimbleIndex = 0;
    this.gameStarted = false;

    this.bonus_game = null;
    this.isBonusGame = false;
    this.isRestoredBaseGame = false;
    this.isRestoredCoin = false;

    this.bets = [];
    this.betAmount = 5;
    this.pickedBetIndex = 0;

    this.characters = ['diablo', 'pirate', 'prisoner', 'asia'];
    this.turns = [];

    this.balance = 1000;
    this.currency = 'usd';
    this.decimal = 2;

    this.is_win = false;
    this.toggleButtons = [
      {
        count: 3,
        active: true
      },
      {
        count: 4,
        active: false
      },
      {
        count: 5,
        active: false
      }
    ];

    this._callbacks = [];

    GlobalDispatcher.add(GameEvents.CHARACTER.CHANGE_HERO, this.onHeroChanged, this);
  }

  onHeroChanged({params}) {
    this.bonus_game.active_hero = params.index;
  }

  setBets(bets) {
    this.change({bets: bets, betAmount: bets[this.pickedBetIndex]})
    GlobalDispatcher.dispatch(GameEvents.UI.SET_BET, this.pickedBetIndex, this)
  }

  subscribe(callback, context) {
    this._callbacks.push({callback, context});
  }

  unsubscribe(callback, context) {
    this._callbacks = this._callbacks.filter(c => c.toString() != {callback, context}.toString());
  }

  change(data) {
    for(let key in data) { this[key] = data[key] }
    this.notify();
  }

  notify() {
    this._callbacks.forEach(callback => callback.callback.call(callback.context, this));
  }

  parseFreeBets(response) {
    if (response.freebets) {
      const freeBetsAvailable = response.freebets.bet_levels;
      const freeBetsIsActive = response.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
      const isLastFreeBet = response.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED;

      if (freeBetsIsActive) {
        this.pickedBetIndex = response.freebets.bet_level;
        this.setBets(freeBetsAvailable);
      } else if (isLastFreeBet) {
        if (!response.bet_index) {
          this.pickedBetIndex = 0;
        }
        this.setBets(this.bets_available);
      }
    } else if (this.bets !== this.bets_available) {
      if (!response.bet_index) {
        this.pickedBetIndex = 0;
      }
      this.setBets(this.bets_available);
    }
  }

}
